import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { div, mul } from 'exact-math';
import { Col, Icon, SubscriptionCart } from 'components';
import { subscriptionsContainer } from './styles';

const SubscriptionsSlider = (props) => {
  const { subscriptions, className } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollContainerRef = useRef();
  const cardRef = useRef();
  const showCartInContainer = div(scrollContainerRef.current?.clientWidth ?? 0, cardRef.current?.clientWidth ?? 0);
  const cart = Math.floor(showCartInContainer);

  useEffect(() => {
    scrollContainerRef?.current?.scrollTo({
      left: mul(currentIndex, cardRef.current?.clientWidth ?? 0),
      behavior: 'smooth',
      top: 0,
    });
  }, [currentIndex]);

  const handlePrevSlide = () =>
    setCurrentIndex((prevIndex) => (prevIndex !== 0 ? prevIndex - 1 : subscriptions.length - cart));

  const handleNextSlide = () =>
    setCurrentIndex((prevIndex) => (prevIndex !== subscriptions.length - cart ? prevIndex + 1 : 0));

  return (
    <div css={subscriptionsContainer} {...(className && { className })}>
      {currentIndex !== 0 && (
        <Icon material size={30} onClick={handlePrevSlide} iconName="arrow_circle_left" className="arrow" />
      )}

      <div className="subscriptions-badge-container" ref={scrollContainerRef}>
        {subscriptions?.map((el, ind) => (
          <Col lg={4} md={6} sm={12} key={ind} className="column-cart" gap={16} ref={cardRef}>
            <SubscriptionCart subscription={el} />
          </Col>
        ))}
      </div>

      {subscriptions?.length - cart !== currentIndex && (
        <Icon material size={30} onClick={handleNextSlide} iconName="arrow_circle_right" className="arrow" />
      )}
    </div>
  );
};

SubscriptionsSlider.propTypes = {
  subscriptions: PropTypes.array,
  className: PropTypes.string,
};

export default SubscriptionsSlider;
