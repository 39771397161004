import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslations } from '@veraio/strank';
import { BackButton, Col, Row, showApiError, TransactionComments, TransactionPaymentDetails } from 'components';
import { validDate } from 'utils';
import { getTransactionById } from 'services';
import { ReactComponent as GreenCashIcon } from 'assets/images/green-cash-icon.svg';
import { transactionDetailsContainer } from './styles';

const TransactionDetails = () => {
  const { getText } = useTranslations();
  const { transactionId } = useParams();
  const [transaction, setTransaction] = useState({});

  useEffect(() => {
    fetchTransactionDetails();
    handleScrollToTop();
  }, []);

  const fetchTransactionDetails = async () => {
    const [res, err] = await getTransactionById(transactionId);

    err ? showApiError(err) : setTransaction(res);
  };

  const handleScrollToTop = () =>
    document
      .getElementById('#transaction-details-header')
      .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

  return (
    <div css={transactionDetailsContainer} id="#transaction-details-header">
      <Row className="header-container">
        <Col sm={6} md={6} lg={4} xl={6}>
          <BackButton />
        </Col>
        <Col sm={6} md={6} lg={8} xl={6} className="header-info-container">
          <GreenCashIcon className="icon" />
          {transaction?.id && <p className="bolded-title">#{transaction?.id}</p>}
          {validDate(transaction?.createdDate) && (
            <p className="gray-text">{moment(transaction?.createdDate).format('DD MMM YYYY - HH:MM')}</p>
          )}
        </Col>
      </Row>
      <h4 className="title">{getText('paymentDetails')}</h4>
      <Row>
        <Col sm={12} md={12} lg={6}>
          <TransactionPaymentDetails transaction={transaction} />

          <h4 className="title">{getText('comments')}</h4>
          <TransactionComments comments={[]} />
        </Col>
        <Col sm={12} md={12} lg={1} />
        <Col sm={12} md={12} lg={5}>
          <div>
            <h4 className="sender-styles">{getText('sender')}</h4>
            <p className="user-info">
              <b>{`${getText('email')}: `}</b>
              {transaction.senderEmail}
            </p>
            <p className="user-info-user-id">
              <b>{`${getText('userId')}: `}</b>
              {transaction?.senderUserId}
            </p>
          </div>
          <div>
            <h4 className="sender-styles">{getText('receiver')}</h4>
            <p className="user-info">
              <b>{`${getText('email')}: `}</b>
              {transaction?.receiverEmail}
            </p>
            <p className="user-info-user-id">
              <b>{`${getText('userId')}: `}</b>
              {transaction?.receiverUserId}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TransactionDetails;
