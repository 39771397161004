import { theme } from 'styles';

export const buySubscriptionContainer = {
  '.batch': {
    backgroundColor: theme.gray700,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.white,
    padding: '12px 64px',
  },

  '.secure-batch': {
    position: 'absolute',
    top: 15,
    left: '20%',
    zIndex: 1000,
    color: theme.successDark,
    backgroundColor: theme.successLighter,
    padding: '4px 12px',
    borderRadius: 50,
    fontSize: 12,
  },

  '.subscription': {
    padding: '64px 128px 16px',

    '.back-button': {
      padding: '0.5em 0',
      marginBottom: 16,
    },

    '.title': {
      fontWeight: 500,
      marginBottom: 12,
    },

    '.subscription-card': {
      padding: 32,
      borderRadius: 10,
      border: `0.5px solid ${theme.gray150}`,
      boxShadow: theme.boxShadowDark,
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 40,
      flexWrap: 'wrap',

      '.subscription-plan-container': {
        marginRight: 32,
        marginBottom: 16,
        '.plan': {
          marginBottom: 16,
        },

        '.plan-price': {
          fontWeight: 600,
          marginRight: 8,
          fontSize: 14,
        },

        '.plan-duration': {
          fontSize: 12,
          fontWeight: 400,
          color: theme.gray400,
        },
      },
    },

    '.action-container': {
      display: 'flex',
      justifyContent: 'flex-end',

      '.icon': {
        marginRight: 4,
      },
    },
  },
};

export const benefits = (isIncluded, hasBvPoints) => [
  {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    color: isIncluded && hasBvPoints ? theme.blue : theme.black,
    fontWeight: isIncluded && hasBvPoints ? 600 : 400,

    '& > p': {
      textDecoration: isIncluded ? 'none' : 'line-through',
    },

    '.bolded': {
      fontWeight: 600,
      marginRight: 4,
    },

    '.icon-success': {
      width: 20,
      height: 20,
      marginRight: 8,
    },

    '.icon': {
      padding: !isIncluded && 4,
      marginRight: !isIncluded && 4,
    },
  },
];
