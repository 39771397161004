import { theme } from 'styles';

export const subscriptionDetailsContainer = {
  borderRadius: 10,
  border: `0.5px solid ${theme.gray150}`,
  boxShadow: theme.boxShadowDark,
  backgroundColor: theme.white,
  padding: 24,

  '.button': {
    fontWeight: 600,
  },

  '.not-have-subscription': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '.empty-icon': {
      width: 326,
      height: 229,
    },

    '.title': {
      fontWeight: 500,
      color: theme.gray800,
    },

    '.subtitle': {
      color: theme.gray400,
      marginBottom: 24,
    },
  },

  '.subscription-card': {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 32,
    flexWrap: 'wrap',

    '.subscription-plan-container': {
      marginRight: 32,
      marginBottom: 16,

      '.subscription-info': {
        marginBottom: 16,

        '.title': {
          color: theme.gray400,
          marginBottom: 4,
        },

        '.price': {
          fontWeight: 600,
          marginRight: 8,
          fontSize: 14,

          '.plan-duration': {
            fontSize: 12,
            fontWeight: 400,
            color: theme.gray400,
          },
        },
      },
    },
  },
};

export const benefitsContainer = (isIncluded, hasBvPoints) => [
  {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    color: isIncluded && hasBvPoints ? theme.blue : theme.black,
    fontWeight: isIncluded && hasBvPoints ? 600 : 400,

    '& > p': {
      textDecoration: isIncluded ? 'none' : 'line-through',
    },

    '.bolded': {
      fontWeight: 600,
      marginRight: 4,
    },

    '.icon-success': {
      width: 20,
      height: 20,
      marginRight: 8,
    },

    '.icon': {
      padding: !isIncluded && 4,
      marginRight: !isIncluded && 4,
    },
  },
];
