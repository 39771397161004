import { theme } from 'styles';

export const walletTransactionContainer = {
  borderRadius: 10,
  border: `0.5px solid ${theme.gray150}`,
  boxShadow: theme.boxShadowDark,
  backgroundColor: theme.white,
  padding: '20px 24px',
  marginBottom: 32,

  '.not-have-transactions': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '.empty-icon': {
      width: 326,
      height: 229,
    },

    '.title': {
      fontWeight: 500,
      color: theme.gray800,
    },
  },

  '.action-button': {
    color: theme.blue,
    fontWeight: 500,
    padding: 0,
  },

  '.transactions-container': {
    '.titles': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    '.transactions-container': {
      '.transactions': {
        padding: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',

        '.date-kind': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          maxWidth: 350,

          '.date': {
            maxWidth: 30,
            textTransform: 'uppercase',
            textAlign: 'center',
            marginRight: 16,
            fontWeight: 500,
          },
        },

        '.status-price': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',

          '.color-batch': {
            marginBottom: 8,
          },

          '@media (max-width: 576px)': {
            alignItems: 'flex-start',
          },
        },

        '&:hover': {
          backgroundColor: theme.gray100,
        },

        '@media (max-width: 576px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
};
