import { useRef, forwardRef, useImperativeHandle } from 'react';
import { useTranslations } from '@veraio/strank';
import { Button, Modal } from 'components';
import { leaveModalContainer } from './styles';

const LeavingModal = forwardRef((props, ref) => {
  const { onLeave, onStay } = props;
  const { getText } = useTranslations();
  const modalRef = useRef();

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current?.open(),
    close: () => modalRef.current?.close(),
  }));

  return (
    <Modal ref={modalRef} closeIcon css={leaveModalContainer}>
      <h4 className="title">{getText('areYouSure')}</h4>
      <p className="subtitle">{getText('leavingPageLoseYourProgress')}</p>
      <div className="action-container">
        <Button small onClick={onLeave}>
          {getText('leavePage')}
        </Button>
        <Button small type="secondary" onClick={onStay}>
          {getText('stayOnPage')}
        </Button>
      </div>
    </Modal>
  );
});

export default LeavingModal;
