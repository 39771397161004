import { useTranslations } from '@veraio/strank';
import { forgotPassword } from '@oneecosystem/authenticate';
import { Button, Form, Input, inputValidation, Icon } from 'components';
import { ROUTES } from 'enums';
import { ReactComponent as Logo } from 'assets/images/logo-horizontal.svg';
import { forgotPasswordContainer } from './styles';

const ForgotPassword = ({ history }) => {
  const { getText } = useTranslations();

  return (
    <div css={forgotPasswordContainer}>
      <Logo onClick={() => history.push(ROUTES.Hotels)} className="logo" />

      <Form onSubmit={() => history.push(forgotPassword())} className="form-container">
        <div className="lock-icon-container">
          <Icon iconName="lock" size={26} />
        </div>
        <h3 className="title">{getText('troubleWithLoggingIn')}</h3>
        <p className="desc">{getText('enterEmailToGetBackIntoYourProfile')}</p>
        <Input
          required
          formId="email"
          margin="0 0 1em"
          label={getText('emailAddress')}
          validate={inputValidation('required', 'email')}
        />

        <div className="action-container">
          {({ onSubmit }) => (
            <Button small linkTo={forgotPassword()} onClick={onSubmit} className="login-button">
              {getText('logIn')}
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default ForgotPassword;
