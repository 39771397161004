import queryString from 'query-string';
import { isArray, isEmpty, isNumber, isString, pick } from 'lodash-es';
import { endOfTheDay, startOfTheDay } from 'utils';

export const getMyTransactionsParams = (options = {}) => {
  const {
    status,
    kinds,
    subKind,
    reason,
    type,
    amountFrom,
    amountTo,
    createdDateFrom,
    createdDateTo,
    transactionId,
    externalTransactionId,
    // sort = {
    //   sortField: 'createdDate',
    //   sortOrder: 'desc',
    // },
    sortByDate,
  } = options;

  const dateRange = {};
  const amountPriceRange = {};

  // const mapSort = (sorting) => ({
  //   sortField: sorting.sortField,
  //   sortOrder: sorting.sortOrder,
  // });

  if (createdDateFrom && createdDateTo) {
    dateRange.createdDateFrom = startOfTheDay(createdDateFrom).format();
    dateRange.createdDateTo = endOfTheDay(createdDateTo).format();
  }

  if (amountFrom && amountTo && amountFrom >= 0 && amountTo >= 0) {
    amountPriceRange.amountFrom = amountFrom;
    amountPriceRange.amountTo = amountTo;
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      // ...(sort && mapSort(sort)),
      ...(isArray(status) && { status }),
      ...(isArray(kinds) && { kinds }),
      ...(isString(subKind) && { subKind }),
      ...(reason && { reason }),
      ...(isString(type) && { type }),
      ...(isNumber(amountFrom) && { amountFrom }),
      ...(isNumber(amountTo) && { amountTo }),
      ...(isString(sortByDate) && { sortByDate }),
      ...(!isEmpty(dateRange) && dateRange),
      ...(!isEmpty(amountPriceRange) && amountPriceRange),
      ...(isNumber(transactionId) && { transactionId }),
      ...(isNumber(externalTransactionId) && { externalTransactionId }),
    },
    { arrayFormat: 'index' },
  );
};
