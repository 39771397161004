import { theme } from 'styles';

export const amenitiesContainer = (hasWrap) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',

  '.amenities-badge-container': {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 16px',
    overflowX: 'hidden',
    width: '100%',

    '.badge': {
      display: 'flex',
      whiteSpace: 'nowrap',
    },

    '@media (max-width: 1199px)': {
      flexWrap: hasWrap ? 'wrap' : 'nowrap',
    },
  },

  '.arrow': {
    color: theme.gray150,
  },
});

export const amenityBadge = (isActive, onlyShow) => [
  {
    border: `1px solid ${isActive ? theme.blue : theme.gray150}`,
    backgroundColor: isActive ? theme.infoLight : theme.white,
    color: isActive ? theme.gray400 : theme.black,
    borderRadius: 10,
    padding: 10,
    marginRight: 16,
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    cursor: onlyShow ? 'default' : 'pointer',

    '&:last-child': {
      marginRight: 0,
    },

    '.icon-star': {
      marginRight: 4,
    },

    '.star': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '.batch-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
];
