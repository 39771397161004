import { theme } from 'styles';

export const roomCartContainer = {
  backgroundColor: theme.gray50,
  marginBottom: 32,

  '.left-side': {
    padding: 16,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '10px 0px 0px 10px',

    '.row': {
      width: '100%',

      '.description': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },

      '.room-title': {
        marginBottom: 8,
      },

      '.square-footage': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.gray300,
        fontWeight: 500,
      },

      '.amenities': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.successDark,
      },
    },
  },

  '.right-side': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 16,
  },
};
