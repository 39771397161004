import { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { isNil, pick } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Pagination, showApiError, Spinner, useUrlParams, LoadingRoomCard, RoomCart } from 'components';
import { getHotelRoomRates } from 'services';
import { ReactComponent as NotHaveRooms } from 'assets/images/not-have.svg';
import { availabilityContainer } from './styles';

const Availability = ({ hotelDetails }) => {
  const { getText } = useTranslations();
  const { queryParams, setQueryParams } = useUrlParams();
  const [hotelRoomRates, setHotelRoomRates] = useState([]);
  const loaderRef = useRef();
  const history = useHistory();

  const fetchHotelRoomRates = async (options) => {
    const filterParams = { ...queryParams, ...history.location?.state?.filters, ...options };
    loaderRef.current?.showLoading();
    setQueryParams(filterParams);

    const [res, err] = await getHotelRoomRates(hotelDetails?.hotelId, filterParams);
    if (err) return showApiError(err);
    loaderRef.current?.hideLoading();
    setHotelRoomRates(res);
    return res;
  };

  return (
    <div css={availabilityContainer}>
      <h3 className="title">{getText('roomTypes')}</h3>
      <div>
        {isNil(hotelRoomRates?.results) ? (
          <LoadingRoomCard />
        ) : hotelRoomRates?.results?.length ? (
          hotelRoomRates?.results?.map((el, ind) => (
            <RoomCart
              key={ind}
              room={el}
              hotelDetails={hotelDetails}
              filters={{
                ...queryParams,
                ...history.location?.state?.filters,
                ...pick(hotelRoomRates, 'searchId', 'sessionId', 'tokenId'),
              }}
            />
          ))
        ) : (
          <div className="not-have-container">
            <NotHaveRooms />
            <h4 className="not-have-title">{getText('notHaveRoomsForThisDates')}</h4>
            <p className="not-have-subtitle">{getText('pleaseChooseNewDates')}</p>
          </div>
        )}
        <Pagination onChange={fetchHotelRoomRates} pageSize={3} justify="center" />
      </div>
      <Spinner ref={loaderRef} text={getText('loadingRooms')} />
    </div>
  );
};

export default Availability;
