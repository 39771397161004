import { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { dateRangeContainer } from './styles';

const PriceRange = (props) => {
  const { min = 0, max = 100, onChange, className } = props;
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef();
  const maxValRef = useRef();
  const range = useRef();

  // Convert to percentage
  const getPercent = useCallback((value) => Math.round(((value - min) / (max - min)) * 100), [min, max]);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value);

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal, onChange]);

  const handleChangeMin = (e) => {
    const value = Math.min(+e.target.value, maxVal - 1);
    setMinVal(value);
    e.target.value = value.toString();
  };

  const handleChangeMax = (e) => {
    const value = Math.max(+e.target.value, minVal + 1);
    setMaxVal(value);
    e.target.value = value.toString();
  };

  return (
    <div css={dateRangeContainer} {...(className && { className })}>
      <div className="min-dot" onDragStart={handleChangeMin} onTouchStart={handleChangeMin} draggable />
      <div className="slot">
        <div ref={minValRef} className="input" onChange={handleChangeMin} />
      </div>
      <div className="max-dot" onDragStart={handleChangeMax} onTouchStart={handleChangeMax} draggable />

      <div className="slider__left-value">{minVal}</div>
      <div className="slider__right-value">{maxVal}</div>
    </div>
  );
};

PriceRange.propTypes = {
  filters: PropTypes.object,
  step: PropTypes.number,
  valueMin: PropTypes.number,
  valueMax: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default PriceRange;
