import { Skeleton } from 'components';
import { reviewContainer, reviewCart } from './styles';

const LoadingReviewCart = () => (
  <div css={reviewContainer}>
    {Array(3)
      .fill()
      .map((_, ind) => (
        <div key={ind} css={reviewCart}>
          <div className="creation-user">
            <Skeleton width={40} height={25} className="review-rating" />
            <Skeleton width={80} height={17} className="desc" />
          </div>
          <Skeleton width="100%" height={77} className="comment" />
          <div className="date-user-styles">
            <Skeleton width={80} height={17} className="comment" />
            <div className="dot" />
            <Skeleton width="50%" height={17} className="comment" />
          </div>
          <hr className="horizontal-line" />
        </div>
      ))}
  </div>
);

export default LoadingReviewCart;
