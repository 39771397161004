import { theme } from 'styles';

export const container = {
  position: 'absolute',
  top: 50,
  right: 10,
  display: 'flex',
  flexDirection: 'column',
  padding: 16,
  backgroundColor: theme.white,
  borderRadius: 6,
  color: theme.primary,
  width: 300,
  boxShadow: theme.boxShadow,

  '& > *': {
    marginBottom: 12,
  },

  '& .button': {
    width: '100%',
  },

  '.grayColor': {
    color: theme.gray,
  },
};

export const profile = {
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `0.5px solid ${theme.gray100}`,
  width: '100%',
  color: theme.gray200,

  '.item': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '20px 16px',

    '&:hover': {
      backgroundColor: theme.gray100,
    },

    '& > h5': {
      color: theme.gray700,
      marginLeft: 12,
    },
  },
};

export const profileNavStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontWeight: 600,
  padding: 8,

  '.avatar-image': {
    width: 26,
    height: 26,
    marginRight: 8,
  },
};

export const icon = {
  marginRight: 12,
};
