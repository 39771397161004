import { theme } from 'styles';
import vector from 'assets/images/vector.svg';

export const walletCartContainer = (walletType) => {
  const walletBackgroundColorMap = {
    coin: {
      backgroundColor: theme.warningLight,
    },
    cash: {
      backgroundColor: theme.blueLight,
    },
  };

  return [
    walletBackgroundColorMap[walletType],
    {
      backgroundImage: `url(${vector})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 100% bottom 80%',
      backgroundSize: 'cover',
      padding: '12px 24px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 10,

      '.wallet-title': {
        marginBottom: 8,
      },

      '.icon': {
        width: 38,
        height: 38,
        marginRight: 12,
      },
    },
  ];
};
