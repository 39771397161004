import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { pick } from 'lodash-es';
import { Localization, User, Button } from 'components';
import { useUserStore } from 'stores';
import { ROUTES } from 'enums';
import { ReactComponent as Logo } from 'assets/images/logo-horizontal.svg';
import { navBarHeaderContainer, navBarLeftSideContainer, navBarRightSideContainer } from './styles';

const NavBar = () => {
  const history = useHistory();
  const { getText } = useTranslations();
  const { isAuthenticated } = useUserStore((userState) => pick(userState, ['isAuthenticated']));

  return (
    <header css={navBarHeaderContainer}>
      <div css={navBarLeftSideContainer}>
        <Logo
          onClick={() => {
            history.push(ROUTES.Hotels);
          }}
        />
      </div>

      <div css={navBarRightSideContainer}>
        <Localization />

        {isAuthenticated ? (
          <User />
        ) : (
          <Button small linkTo={ROUTES.Login} className="login-styles">
            {getText('logIn')}{' '}
          </Button>
        )}
      </div>
    </header>
  );
};

NavBar.propTypes = {
  logoLink: PropTypes.string,
};

export default NavBar;
