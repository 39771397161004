import { useEffect } from 'react';
import moment from 'moment';
import { useTranslations } from '@veraio/strank';
import { Button, Col, Icon, Row, CheckoutPriceCart, BuyWalletsView, AccountPaymentMethodsView } from 'components';
import { FEATURE_TYPES } from 'enums';
import { checkoutPaymentContainer } from './styles';

const Payment = ({
  hasError,
  paymentData,
  roomDetails,
  filters,
  onSetWallets,
  onCompleted,
  onBack,
  onScrollToTop,
  onSetHasError,
  onSetAccountAndPaymentMethod,
  onScrollToError,
}) => {
  const { getText } = useTranslations();
  const checkIn = moment(filters?.checkIn);
  const checkOut = moment(filters?.checkOut);
  const days = checkOut.diff(checkIn, 'days');

  useEffect(() => {
    onScrollToTop();
  }, []);

  return (
    <div css={checkoutPaymentContainer} id="#header">
      <div className="payment-title">
        <h3>{getText('payment')}</h3>
      </div>
      <Row gap={32} className="payments-container">
        <Col sm={12} md={7} lg={7} id="#priceError">
          <AccountPaymentMethodsView
            feature={FEATURE_TYPES.BOOKING}
            hasError={hasError}
            paymentData={paymentData}
            onSetHasError={onSetHasError}
            onSetWallets={onSetWallets}
            onSetAccountAndPaymentMethod={onSetAccountAndPaymentMethod}
            onScrollToError={onScrollToError}
          />
          <div className="action-container">
            <Button small type="secondary" onClick={onBack}>
              {getText('back')}
            </Button>
            <Button onClick={() => onCompleted(paymentData?.values)}>
              <Icon material iconName="lock" size={14} className="icon" />
              {getText('confirm')}
            </Button>
          </div>
        </Col>

        <Col sm={12} md={5} lg={5}>
          <BuyWalletsView
            withoutPayPrice
            className="wallets-container"
            buyItemPrice={roomDetails?.priceDetails?.price}
            paymentMethod={paymentData?.values?.paymentMethod}
            wallets={paymentData?.wallets}
          />
          <CheckoutPriceCart roomRateData={roomDetails} days={days} />
        </Col>
      </Row>
    </div>
  );
};

export default Payment;
