import moment from 'moment';
import { capitalize, isNil } from 'lodash-es';
import { ColorBatch, PriceLabel, TableActions } from 'components';
import {
  DEFAULT_CURRENCY,
  TRANSACTION_STATUS_COLOR,
  TRANSACTION_STATUS_TYPE,
  TRANSACTION_SUB_KINDS,
  TRANSACTION_TYPE,
} from 'enums';
import { columnContainer, coloredAmount, linkButton } from './styles';

export const myTransactionsColumns = (getText, selectedCurrency) => [
  {
    name: getText('dateOfCreation'),
    render: ({ createdDate }) => moment(createdDate).format('DD MMM YYYY'),

    width: '130px',
    // sortable: true,
  },
  {
    name: getText('transactionIds'),
    render: (row) => (
      <div css={columnContainer}>
        <p className="bold-text">#{row.id}</p>
        <p className="gray-text">
          {getText('ext')}: <span>#{row.externalTransactionId}</span>
        </p>
      </div>
    ),
    width: '170px',
  },
  {
    name: getText('status'),
    render: (row) => (
      <ColorBatch withoutStartCase type={TRANSACTION_STATUS_COLOR[row?.status]?.color}>
        {getText(TRANSACTION_STATUS_TYPE[row?.status])}
      </ColorBatch>
    ),
    width: '170px',
  },
  {
    name: getText('sender'),
    render: (row) => (
      <div css={columnContainer}>
        <p className="bold-text">{row?.senderEmail}</p>
        <p className="gray-text">{row?.senderUserId}</p>
      </div>
    ),
  },
  {
    name: getText('receiver'),
    render: (row) => (
      <div css={columnContainer}>
        <p className="bold-text">{row?.receiverEmail}</p>
        <p className="gray-text">{row?.receiverUserId}</p>
      </div>
    ),
  },
  {
    name: getText('amount'),
    render: ({ transactionType, transactionSubKind, amount }) => (
      <p css={coloredAmount(transactionType === TRANSACTION_TYPE.DEBIT)}>
        {`${transactionType === TRANSACTION_TYPE.CREDIT ? '+' : '-'} `}{' '}
        {transactionSubKind === TRANSACTION_SUB_KINDS.OTHER ? (
          amount
        ) : transactionSubKind === TRANSACTION_SUB_KINDS.BOOKING_PAYMENT_WITH_CRYPTO ? (
          <PriceLabel crypto={Number(amount)} ignoreEmpty />
        ) : (
          <PriceLabel
            fiat={Number(amount)}
            fiatOriginCurrency={DEFAULT_CURRENCY.code}
            fiatDesiredCurrency={selectedCurrency?.code}
          />
        )}
      </p>
    ),
    width: '150px',
  },
  {
    name: getText('reason'),
    render: (row) => (
      <div css={columnContainer}>
        <p className="bold-text">
          {getText('reason')}: <span>{row.reason}</span>
        </p>
        {!isNil(row.transactionKind) && (
          <p className="bold-text">
            {getText('kind')}: <span>{row.transactionKind}</span>
          </p>
        )}
        {!isNil(row.transactionSubKind) && (
          <p className="bold-text">
            {getText('subKind')}: <span>{row.transactionSubKind}</span>
          </p>
        )}
        {!isNil(row.transactionType) && (
          <p className="bold-text">
            {getText('transactionType')}: <span>{getText(capitalize(row?.transactionType))}</span>
          </p>
        )}
      </div>
    ),
  },
  {
    name: 'Actions',
    render: (transaction) => (
      <TableActions
        mainAction={{
          type: 'link',
          linkTo: { pathname: `/transactions/details/${transaction?.id}`, state: { transaction } },
          children: getText('viewDetails'),
          css: linkButton,
        }}
      />
    ),
    width: '110px',
  },
];
