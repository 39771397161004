import { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isFunction, isNil, uniqueId, isArray, omit, isEmpty } from 'lodash-es';
import { Pagination, Skeleton, showApiError, useUrlParams, Spinner } from 'components';
import TableHead from './TableHead';
import TableBody from './TableBody';
import { NoResults } from './NoResults';
import { tableContainer, filterBarContainer } from './styles';

const omitInternal = (el) => omit(el, '_table');

const Table = forwardRef((props, reference) => {
  const {
    getDataMethod,
    getDataKey = 'data',
    columns,
    pageSize,
    emptyMessage: emptyMessageProp,
    filterBar: FilterBar,
    footerBar,
    className,
    updateOn,
    onSelect,
    hasPagination = true,
    hasSelection: hasSelectionProp,
    showLoader = true,
    fillUpUrlParams = false,
    renderRow,
    onRowClick,
    rowLink,
    filterKeys,
    requiredFilterKeys,
    defaultQueryParams,
  } = props;

  const { setQueryParams } = useUrlParams();
  const [data, setData] = useState(null);
  const [emptyMessage, setEmptyMessage] = useState(emptyMessageProp);
  const hasSelection = isFunction(onSelect) || hasSelectionProp;
  const loaderRef = useRef();
  const paginationRef = useRef();

  useImperativeHandle(reference, () => ({
    getData: (params) => getData(params),
    selectRow: (row) => selectRow(row),
    getSelected: () => data.filter((el) => el._table.isSelected).map(omitInternal),
    deselectAll: () => setData((prev) => prev.map((el) => ({ ...el, _table: { ...el._table, isSelected: false } }))),
    changeEmptyMessage: setEmptyMessage,
  }));

  useEffect(
    () => {
      !hasPagination && getData();
    },
    isArray(updateOn) ? updateOn : [updateOn],
  );

  const getData = async (options) => {
    if (isEmpty(options)) return;
    // Wrap all filters into spinner to indicate that results are coming
    showLoader && loaderRef.current?.showLoading();
    const [res, err] = await getDataMethod(options);

    showLoader && loaderRef.current?.hideLoading();
    if (err) return showApiError(err);

    setData(
      res
        ? res[getDataKey]?.map((el) => ({
            ...el,
            _table: { isSelected: false, uuid: uniqueId() },
          }))
        : [],
    );
    // By default all request options will be save into the ulr, so all filters can be kept
    fillUpUrlParams && setQueryParams(options);

    return res;
  };

  const clearTableData = () => {
    setData([]);
    paginationRef.current?.resetFilters();
  };

  const selectRow = (value, row) => {
    // If row is not passed mark all rows from the table
    const compare = (el) => !row || el._table.uuid === row._table.uuid;

    setData((prev) => {
      const newRows = prev.map((el) => (compare(el) ? { ...el, _table: { ...el._table, isSelected: value } } : el));
      isFunction(onSelect) && onSelect(newRows.filter((el) => el._table.isSelected).map(omitInternal));

      return newRows;
    });
  };

  const tableDataProps = {
    getData,
    data,
    setData,
    selectRow,
    setEmptyMessage,
    clearTableData,
    paginationRef,
  };

  const filterProps = { filterKeys, requiredFilterKeys, defaultQueryParams };

  return (
    <div css={tableContainer} {...(className && { className })}>
      {FilterBar && (
        <div css={filterBarContainer}>
          <Spinner ref={loaderRef} />
          <FilterBar {...tableDataProps} {...filterProps} />
        </div>
      )}
      <TableHead
        hasSelection={hasSelection}
        hasFilterBar={!isNil(FilterBar)}
        columns={columns}
        allSelected={data?.every((el) => el?._table?.isSelected)}
        {...tableDataProps}
      />
      {data?.length === 0 ? (
        <NoResults emptyMessage={emptyMessage} />
      ) : !data ? (
        <Skeleton count={pageSize ?? 10} height={30} marginBottom={12} />
      ) : (
        <div className="container-body">
          <TableBody
            hasSelection={hasSelection}
            data={data}
            columns={columns}
            renderRow={renderRow}
            onRowClick={onRowClick}
            rowLink={rowLink}
            {...tableDataProps}
          />
        </div>
      )}

      {hasPagination && (
        <Pagination ref={paginationRef} justify="center" onChange={getData} {...filterProps} {...props} />
      )}
      {footerBar && footerBar(tableDataProps)}
    </div>
  );
});

Table.propTypes = {
  getDataMethod: PropTypes.func,
  columns: PropTypes.array,
  pageSize: PropTypes.number,
  pageIndex: PropTypes.number,
  emptyMessage: PropTypes.string,
  filterBar: PropTypes.func,
  footerBar: PropTypes.func,
  updateOn: PropTypes.any,
  className: PropTypes.string,
  getDataKey: PropTypes.string,
  onSelect: PropTypes.func,
  hasPagination: PropTypes.bool,
  hasSelection: PropTypes.bool,
  pageSizes: PropTypes.array,
  showLoader: PropTypes.bool,
  fillUpUrlParams: PropTypes.bool,
  renderRow: PropTypes.func,
  onRowClick: PropTypes.func,
  rowLink: PropTypes.any,
  withPageSize: PropTypes.bool,
  showResultsIndicator: PropTypes.bool,
  filterKeys: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  requiredFilterKeys: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  defaultQueryParams: PropTypes.object,
};

export default Table;
