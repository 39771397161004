import { theme } from 'styles';

export const roomRateContainer = (isExpand, hasDiscount) => ({
  backgroundColor: theme.gray100,
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '0px 10px 10px 0px',
  marginBottom: 8,
  height: isExpand ? 'auto' : '100%',

  '.title': {
    fontWeight: 500,
  },

  '.prices-styles': {
    '.price-container': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',

      '.price': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.gray800,

        '.original-price': {
          color: theme.gray300,
          marginRight: 8,
          textDecoration: 'line-through',
        },

        '.discounted-price': {
          color: hasDiscount ? theme.successDark : theme.gray800,
          marginRight: 4,
        },

        '.perNight': {
          fontSize: 14,
          color: theme.gray200,
          fontWeight: 500,
        },
      },

      '.button': {
        fontWeight: 600,
      },

      '.color-batch': {
        marginBottom: 4,
        marginRight: 4,
      },
    },

    '.horizontal-line': {
      backgroundColor: theme.gray200,
    },

    '.fee-action': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',

      '.fee-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.gray500,

        '.fee': {
          fontWeight: 500,
          marginRight: 8,
        },
      },

      '.button-view': {
        padding: 0,

        '&:active': { outline: 'none' },

        '.action-view': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          color: theme.blue,
          fontWeight: 600,
        },
      },
    },
  },
});
