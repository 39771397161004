export const ROUTES = {
  // private routes
  // REMOVE TAG HIDE FOR FIRST VERSION
  // BuySubscription: '/subscriptions/:subscriptionId',
  HotelCheckout: '/hotels/:hotelId/checkout',
  MyBookings: '/bookings',
  MyBookingsDetails: '/bookings/:bookingStatus/:bookingId',
  MyProfile: '/my-profile',
  SuccessCancelBooking: '/bookings/success-cancel',
  SuccessCheckout: '/hotels/success-checkout',
  Transactions: '/transactions',
  TransactionsDetails: '/transactions/details/:transactionId',

  // public routes
  AppStore: 'https://www.apple.com/app-store/',
  AuthorizationCallback: '/authorization-callback',
  ForgotPassword: '/forgot-password',
  GoogleStore: 'https://play.google.com/store/',
  HotelDetails: '/hotels/:hotelId',
  Hotels: '/hotels',
  HotelsListing: '/hotels/listing',
  Logout: '/logout',
  Login: '/login',
  PrivacyPolicy: '/privacy-policy',
  // REMOVE TAG HIDE FOR FIRST VERSION
  // Subscriptions: '/subscriptions',
  TermsAndConditions: '/terms-and-conditions',
};
