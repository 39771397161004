import { theme } from 'styles';

export const userContentInformation = {
  borderRadius: 20,
  boxShadow: theme.boxShadowDarker,
  backgroundColor: theme.white,
  padding: '40px 20px 20px 20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '.avatar-image': {
    width: 60,
    height: 60,
    marginRight: 8,
  },

  '.name': {
    fontWeight: 500,
    color: theme.gray800,
  },

  '.phone-email': {
    fontSize: 16,
    color: theme.gray400,
  },

  '.external-button': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '24px 0',

    '.text': {
      fontSize: 16,
      color: theme.gray400,
      marginRight: 4,
    },

    '.button-life': {
      color: theme.blue,
      fontWeight: 600,
      padding: 0,

      '.button-text': {
        marginRight: 8,
      },
    },

    '@media (max-width: 576px)': {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
};
