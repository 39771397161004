import { useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { Button, Dropdown, Icon } from 'components';
import { currencyLanguageContainer } from './styles';

const CurrencyLanguageCart = ({ iconName, title, language, currency, onSaveCurrency, className }) => {
  const { getText, allEnvLanguages, changeLanguage } = useTranslations();
  const fiatCurrencies = useCurrencies((currenciesState) => currenciesState?.fiatCurrencies);
  const [showEdited, setShowEdited] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const handleSaveChanges = () => {
    language && changeLanguage(selectedValue?.code);
    currency && onSaveCurrency(selectedValue?.code);
    setShowEdited(false);
  };

  return (
    <div css={currencyLanguageContainer(showEdited)} {...(className && { className })}>
      <div className="title-icon">
        <Icon iconName={iconName} size={24} color="gray500" className="icon" />
        <p className="title-styles">{title}</p>
      </div>
      {showEdited ? (
        <div>
          <Dropdown
            noClear
            className="dropdown"
            value={language ? language : currency}
            options={language ? allEnvLanguages : fiatCurrencies}
            displayKey={language ? 'name' : 'code'}
            onChange={(value) => setSelectedValue(value)}
          />
          <div className="buttons-container">
            <Button small onClick={handleSaveChanges} className="save-button">
              {getText('save')}
            </Button>
            <Button small type="secondary" onClick={() => setShowEdited(false)} className="cancel-button">
              {getText('cancel')}
            </Button>
          </div>
        </div>
      ) : (
        <div className="button-language">
          <div className="language-container">
            {language && <p className="language-code-symbol">{language}</p>}
            {currency && (
              <div className="currency">
                <p className="language-code-symbol">{currency?.code}</p>
                <div className="dot" />
                <p className="language-code-symbol">{currency?.symbol}</p>
              </div>
            )}
          </div>
          <Button type="link" onClick={() => setShowEdited(true)} className="button">
            {getText('edit')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CurrencyLanguageCart;
