import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslations } from '@veraio/strank';
import { Icon } from 'components';
import { ROUTES } from 'enums';
import { useUserStore } from 'stores';
import { leftSubscriptionContainer } from './styles';

const LeftSubscriptionBanner = ({ show, className }) => {
  const { getText } = useTranslations();
  const user = useUserStore();
  const [showMessage, setShowMessage] = useState(show ?? true);
  const history = useHistory();

  const duration = user?.userInfo?.subscription?.duration;
  const startDate = user?.userInfo?.subscription?.startDate;
  const subscriptionLeft = moment(startDate).add(duration, 'months');
  const daysLeft = subscriptionLeft.diff(moment(), 'days');
  const showBanner = daysLeft <= 10;

  const handleCloseBanner = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMessage(false);
  };

  return (
    showBanner &&
    showMessage && (
      <div
        css={leftSubscriptionContainer}
        tabIndex={0}
        role="button"
        onClick={() => history.push(ROUTES.Subscriptions)}
        {...(className && { className })}>
        <Icon iconName="warning" className="warning-icon" />
        <p className="text">
          {daysLeft < 0
            ? getText('daysLeftSubscriptionBuy')
            : getText('daysLeftSubscriptionInformation', {
                days: daysLeft,
                subscriptionName: user?.userInfo?.subscription?.type,
              })}
        </p>
        <Icon iconName="close" className="close-icon" onClick={handleCloseBanner} />
      </div>
    )
  );
};

LeftSubscriptionBanner.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
};

export default LeftSubscriptionBanner;
