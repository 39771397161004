import { useRef, forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import {
  Modal,
  ColorBatch,
  useUrlParams,
  Row,
  Col,
  Button,
  PriceLabel,
  PriceRange,
  Icon,
  LabelCountCheck,
} from 'components';
import { HOTEL_ENTERTAINMENT_MAP, DEFAULT_CURRENCY } from 'enums';
import { modalContainer, badge, guestRate } from './styles';

const filtersToClear = [
  'propertyThemes',
  'rateOptions',
  'reviewRatings',
  'bedsCount',
  'neighborhoods',
  'brands',
  'starRatings',
  'amenities',
  'propertyTypes',
];

const FiltersModal = forwardRef((props, ref) => {
  const { filters, onApplyFilters } = props;
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  const { queryParams, clearQueryParams, setQueryParams } = useUrlParams();
  const modalRef = useRef();
  const [selectedFilters, setSelectedFilters] = useState({
    propertyThemes: queryParams?.propertyThemes ?? [],
    rateOptions: queryParams?.rateOptions ?? [],
    reviewRatings: queryParams?.reviewRatings ?? [],
    bedsCount: queryParams?.bedsCount ?? [],
    neighborhoods: queryParams?.neighborhoods ?? [],
    brands: queryParams?.brands ?? [],
    starRatings: queryParams?.starRatings ?? [],
    amenities: queryParams?.amenities ?? [],
    propertyTypes: queryParams?.propertyTypes ?? [],
  });

  const averagePriceForNight = 22;
  const isActive = (items, elementID) => items && items?.some((el) => el === elementID);
  const filterItems = (items, element) =>
    items?.some((el) => el === element) ? items?.filter((el) => el !== element) : [...items, element];

  const convertedToLocalCurrencyPrice = (price) =>
    price && (
      <PriceLabel
        fiat={Number(price)}
        fiatOriginCurrency={DEFAULT_CURRENCY.code}
        fiatDesiredCurrency={selectedCurrency?.code}
      />
    );

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current?.open(),
    close: () => modalRef.current?.close(),
  }));

  const handleClearAll = () => {
    clearQueryParams(filtersToClear);
    onApplyFilters(omit(queryParams, filtersToClear));
    setSelectedFilters({
      propertyThemes: [],
      rateOptions: [],
      reviewRatings: [],
      bedsCount: [],
      neighborhoods: [],
      brands: [],
      starRatings: [],
      amenities: [],
      propertyTypes: [],
    });
    modalRef.current.close();
  };

  const handleApplyFilters = async () => {
    await onApplyFilters({ ...queryParams, selectedFilters });
    setQueryParams(selectedFilters);
    modalRef.current?.close();
  };

  return (
    <Modal ref={modalRef} closeIcon css={modalContainer}>
      <h4>{getText('filters')}</h4>
      <hr className="horizontal-line" />

      <div className="action-container">
        <Button type="link" onClick={handleClearAll}>
          {getText('clearAll')}
        </Button>
        <Button onClick={handleApplyFilters}>{getText('applyFilters')}</Button>
      </div>

      <div className="scrollable-container">
        <h5 className="title">{getText('propertyThemes')}</h5>
        <Row verticalGap={8}>
          {filters?.propertyThemes.map((el, ind) => (
            <Col horizontalGap={8} key={ind} md={6} sm={12} onClick={(e) => e.stopPropagation()}>
              <div className="badge">
                <ColorBatch
                  withoutStartCase
                  type="default"
                  css={badge(
                    selectedFilters?.propertyThemes && isActive(selectedFilters?.propertyThemes, el?.id),
                    '16px',
                  )}
                  onClick={() =>
                    setSelectedFilters((prev) => ({
                      ...prev,
                      propertyThemes: filterItems(prev?.propertyThemes, el?.id),
                    }))
                  }>
                  <div className="badge-container">
                    <Icon size={25} material iconName={HOTEL_ENTERTAINMENT_MAP[el?.id]?.icon} className="icon" />{' '}
                    <p>{getText(HOTEL_ENTERTAINMENT_MAP[el?.id]?.text)}</p>
                  </div>
                </ColorBatch>
              </div>
            </Col>
          ))}
        </Row>

        <hr className="horizontal-line" />
        <h5 className="title">{getText('rateOptions')}</h5>
        <LabelCountCheck
          labelFilter="rateOptions"
          options={filters?.rateOptions}
          selectedOptions={selectedFilters?.rateOptions}
          onChange={(value) =>
            setSelectedFilters((prev) => ({ ...prev, rateOptions: filterItems(prev?.rateOptions, value?.id) }))
          }
        />

        <hr className="horizontal-line" />
        <h5 className="title">{getText('guestRating')}</h5>
        <div className="rating-container">
          {filters?.reviewRatings?.map((el, ind) => (
            <div
              key={ind}
              role="button"
              tabIndex={0}
              onClick={() =>
                setSelectedFilters((prev) => ({
                  ...prev,
                  reviewRatings: filters?.reviewRatings
                    .filter((element) => element.id >= el.id)
                    ?.map((item) => item?.id),
                }))
              }
              css={guestRate(
                selectedFilters?.reviewRatings && el.id === selectedFilters?.reviewRatings[0],
                selectedFilters?.reviewRatings && isActive(selectedFilters?.reviewRatings, el?.id),
              )}>
              {el.id}+
            </div>
          ))}
        </div>

        <hr className="horizontal-line" />
        <h5 className="title">{getText('priceRangeForNight')}</h5>
        <p className="subtitle">
          <span>{getText('averagePriceIs')}</span>
          {convertedToLocalCurrencyPrice(averagePriceForNight)}
          {getText('priceForNight')}
        </p>
        <PriceRange
          min={convertedToLocalCurrencyPrice(filters?.priceRange.min)}
          max={convertedToLocalCurrencyPrice(filters?.priceRange.max)}
          step={0.01}
          onChange={({ min, max }) => ({
            price: { min: convertedToLocalCurrencyPrice(min), max: convertedToLocalCurrencyPrice(max) },
          })}
        />

        <hr className="horizontal-line" />
        <h5 className="title">{getText('numberOfBeds')}</h5>
        <LabelCountCheck
          labelFilter="bedsCount"
          options={filters?.bedsCount}
          selectedOptions={selectedFilters?.bedsCount}
          onChange={(value) =>
            setSelectedFilters((prev) => ({ ...prev, bedsCount: filterItems(prev?.bedsCount, value?.id) }))
          }
        />

        <hr className="horizontal-line" />
        <h5 className="title">{getText('neighborhoods')}</h5>
        <LabelCountCheck
          labelFilter="neighborhoods"
          options={filters.neighborhoods}
          selectedOptions={selectedFilters?.neighborhoods}
          onChange={(value) =>
            setSelectedFilters((prev) => ({ ...prev, neighborhoods: filterItems(prev?.neighborhoods, value?.id) }))
          }
        />

        <hr className="horizontal-line" />
        <h5 className="title">{getText('brands')}</h5>
        <LabelCountCheck
          labelFilter="brands"
          options={filters.brands}
          selectedOptions={selectedFilters?.brands}
          onChange={(value) =>
            setSelectedFilters((prev) => ({ ...prev, brands: filterItems(prev?.brands, value?.id) }))
          }
        />

        <hr className="horizontal-line" />
        <h5 className="title">{getText('hotelStarLevel')}</h5>
        <div className="stars-container">
          {filters.starRatings?.map((el, ind) => (
            <div key={ind} className="badge">
              <ColorBatch
                withoutStartCase
                type="default"
                css={badge(selectedFilters?.starRatings && isActive(selectedFilters?.starRatings, el?.id), '8px')}
                onClick={() =>
                  setSelectedFilters((prev) => ({ ...prev, starRatings: filterItems(prev?.starRatings, el?.id) }))
                }>
                <div className="star">
                  <Icon material iconName="hotel_class" className="icon-star" />
                  {getText('stars', { stars: el.id })}
                </div>
              </ColorBatch>
            </div>
          ))}
        </div>

        <hr className="horizontal-line" />
        <h5 className="title">{getText('amenities')}</h5>
        <LabelCountCheck
          labelFilter="amenities"
          options={filters.amenities}
          selectedOptions={selectedFilters?.amenities}
          onChange={(value) =>
            setSelectedFilters((prev) => ({ ...prev, amenities: filterItems(prev?.amenities, value?.id) }))
          }
        />

        <hr className="horizontal-line" />
        <h5 className="title">{getText('propertyType')}</h5>
        <LabelCountCheck
          labelFilter="propertyTypes"
          options={filters.propertyTypes}
          selectedOptions={selectedFilters?.propertyTypes}
          onChange={(value) =>
            setSelectedFilters((prev) => ({ ...prev, propertyTypes: filterItems(prev?.propertyTypes, value?.id) }))
          }
        />
      </div>
    </Modal>
  );
});

FiltersModal.propTypes = {
  filters: PropTypes.object,
  onApplyFilters: PropTypes.func,
};

export default FiltersModal;
