import { theme } from 'styles';

export const accountPaymentMethodsViewContainer = (hasError) => ({
  '.title': {
    fontWeight: 500,
    marginBottom: 12,
  },

  '.error-container': {
    marginBottom: 32,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '.error-text': {
      color: theme.errorDark,
    },

    '.error-icon': {
      marginRight: 4,
    },
  },

  '.accounts-dropdown': {
    marginBottom: hasError ? 4 : 12,

    '.input-label': {
      marginTop: 8,
    },

    '.select-dropdown': {
      paddingTop: 20,
      border: hasError.senderAccountId && `1px solid ${theme.errorDark}`,
    },
  },
});

export const errorContent = {
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: 32,
  color: theme.error,

  '.error-icon': {
    marginRight: 4,
    width: 'auto',
    height: '1rem',
    maxHeight: '100%',
  },
};
