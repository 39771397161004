import { theme } from 'styles';

export const homeSearchContainer = {
  position: 'absolute',
  left: '15%',
  right: '15%',
  bottom: -24,
  zIndex: 10,
  backgroundColor: theme.white,
  borderRadius: 12,
  boxShadow: theme.boxShadow,

  '.search-bar': {
    borderRight: `1px solid ${theme.gray150}`,
    borderRadius: 0,
    '.suggestion': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 16,

      '.icon-container': {
        padding: '6px 8px',
        backgroundColor: theme.infoLight,
        borderRadius: 10,
        marginRight: 12,
      },
    },

    '& > div': {
      border: 'none',
      backgroundColor: theme.white,
    },
  },

  '.date-range-picker': {
    '.picker-container': {
      backgroundColor: theme.white,
      border: 'none',
      borderRight: `1px solid ${theme.gray150}`,
    },

    '.DateRangePicker': {
      '.DateRangePickerInput': {
        backgroundColor: theme.white,
        borderRadius: '0 !important',
        border: 'none !important',
        height: 48,

        '.DateInput_input': {
          backgroundColor: theme.white,
          padding: 0,
        },
      },
    },
  },

  '.edit-container': {
    height: 48,
    backgroundColor: theme.white,

    '& > div:first-of-type': {
      backgroundColor: theme.white,
    },

    '.container': {
      backgroundColor: theme.white,
    },

    '.container-visitors': {
      backgroundColor: theme.white,
    },
  },
};
