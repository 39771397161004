import { useRef } from 'react';
import { isNil } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import LocalizationModal from './LocalizationModal';
import { DEFAULT_CURRENCY } from 'enums';
import { localizationContainer } from './styles';

const Localization = () => {
  const modalRef = useRef();
  const { language } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);

  return (
    <>
      <div role="button" tabIndex={0} css={localizationContainer} onClick={() => modalRef.current.open()}>
        <h5>{language?.code?.toUpperCase()}</h5>
        <div className="vertical-line" />
        <h5>{!isNil(selectedCurrency) ? selectedCurrency?.code : DEFAULT_CURRENCY?.code}</h5>
      </div>

      <LocalizationModal ref={modalRef} />
    </>
  );
};

export default Localization;
