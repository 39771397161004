import { useTranslations } from '@veraio/strank';
import { pick } from 'lodash-es';
//  REMOVE TAG HIDE FOR FIRST VERSION
// import moment from 'moment';
import { MenuItem, SideNav, NavBar } from 'components';
import { useUserStore } from 'stores';
import { ROUTES } from 'enums';
import {
  // REMOVE TAG HIDE FOR FIRST VERSION
  // BuySubscription,
  HotelCheckout,
  TransactionsPage,
  MyProfile,
  MyBookings,
  MyBookingsDetails,
  SuccessCancelBooking,
  SuccessCheckout,
  TransactionDetails,
} from './private';
import {
  Footer,
  HotelDetails,
  HotelsHome,
  HotelsListing,
  NotFound,
  PrivacyPolicy,
  TermsAndConditions,
  // REMOVE TAG HIDE FOR FIRST VERSION
  // SubscriptionPage
} from './public';
// REMOVE TAG HIDE FOR FIRST VERSION
// import subscriptionIcon from 'assets/images/subscription-icon.svg';

const Paths = (
  isAuthenticated,
  //  REMOVE TAG HIDE FOR FIRST VERSION
  // hasSubscription
) => [
  {
    url: ROUTES.HotelsListing,
    component: HotelsListing,
  },
  isAuthenticated && {
    //  REMOVE TAG HIDE FOR FIRST VERSION // hasSubscription &&
    url: ROUTES.SuccessCheckout,
    component: SuccessCheckout,
  },
  isAuthenticated && {
    //  REMOVE TAG HIDE FOR FIRST VERSION // hasSubscription &&
    url: ROUTES.HotelCheckout,
    component: HotelCheckout,
  },
  {
    url: ROUTES.HotelDetails,
    component: HotelDetails,
  },
  isAuthenticated && {
    url: ROUTES.MyProfile,
    component: MyProfile,
  },
  isAuthenticated && {
    //  REMOVE TAG HIDE FOR FIRST VERSION // hasSubscription &&
    url: ROUTES.SuccessCancelBooking,
    component: SuccessCancelBooking,
  },
  isAuthenticated && {
    url: ROUTES.MyBookingsDetails,
    component: MyBookingsDetails,
  },
  isAuthenticated && {
    url: ROUTES.MyBookings,
    component: MyBookings,
  },
  isAuthenticated && {
    url: ROUTES.TransactionsDetails,
    component: TransactionDetails,
  },
  isAuthenticated && {
    url: ROUTES.Transactions,
    component: TransactionsPage,
  },
  // REMOVE TAG HIDE FOR FIRST VERSION
  // isAuthenticated && {
  //   url: ROUTES.BuySubscription,
  //   component: BuySubscription,
  // },
  {
    url: ROUTES.PrivacyPolicy,
    component: PrivacyPolicy,
  },
  {
    url: ROUTES.TermsAndConditions,
    component: TermsAndConditions,
  },
];

export const Routes = () => {
  const { isAuthenticated } = useUserStore((userState) => pick(userState, ['isAuthenticated']));
  const { getText } = useTranslations();

  // const { isAuthenticated, userInfo } = useUserStore((userState) => pick(userState, ['isAuthenticated', 'userInfo']));
  //  REMOVE TAG HIDE FOR FIRST VERSION
  // const subscriptionLeft = moment(userInfo?.subscription?.startDate).add(userInfo?.subscription?.duration, 'months');
  // const isLeftSubscription = moment(subscriptionLeft).isBefore(moment());

  return (
    <SideNav
      homeRoute={ROUTES.Hotels}
      routes={Paths(
        isAuthenticated,
        //  REMOVE TAG HIDE FOR FIRST VERSION
        // !isLeftSubscription
      ).filter(Boolean)}
      notFoundComponent={NotFound}
      topNavigation={NavBar}
      footer={Footer}>
      <MenuItem url={ROUTES.Hotels} icon="corporate_fare" label={getText('hotels')} component={HotelsHome} />
      {/*
      REMOVE TAG HIDE FOR FIRST VERSION
      <MenuItem
        url={ROUTES.Subscriptions}
        icon={subscriptionIcon}
        label={getText('subscriptions')}
        component={SubscriptionPage}
      /> */}
    </SideNav>
  );
};
