import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { showApiError, PaymentMethodCart } from 'components';
import { getAllPaymentMethodsByFeature } from 'services';
import { paymentCartContainer } from './styles';

const PaymentMethodsView = ({ feature, values, hasError, onSetPaymentMethod, className }) => {
  const [paymentMethods, setPaymentMethods] = useState(null);

  useEffect(() => {
    fetchAllPaymentMethods();
  }, []);

  const fetchAllPaymentMethods = async () => {
    const [res, err] = await getAllPaymentMethodsByFeature(feature);

    if (err) return showApiError(err);
    setPaymentMethods(res?.results.filter((el) => el.isActive && el.isVisible));
  };

  return (
    <div css={paymentCartContainer(hasError)} {...(className && { className })} id="#paymentMethod">
      {paymentMethods?.map((el, ind) => (
        <PaymentMethodCart
          key={ind}
          paymentMethod={el}
          onClick={(val) => onSetPaymentMethod(val)}
          value={
            (!isEmpty(values) && el?.name === values?.paymentMethodName) || el?.name === values?.paymentMethod?.name
          }
          className="payment-cart"
        />
      ))}
    </div>
  );
};

export default PaymentMethodsView;
