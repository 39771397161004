import { theme } from 'styles';

export const featuresTypeCartContainer = {
  padding: 24,
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: theme.boxShadowLighter,
  width: '100%',
  minWidth: 200,
  marginBottom: 20,
  cursor: 'pointer',

  '&:last-child': {
    marginBottom: 0,
  },

  '.type-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '.type-icon': {
      marginRight: 12,
    },

    '.type-title': {
      fontSize: 16,
      fontWeight: 500,
      color: theme.gray800,
      marginRight: 8,
    },

    '.type-length': {
      fontSize: 16,
      fontWeight: 600,
      color: theme.gray400,
    },
  },
};
