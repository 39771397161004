import { getReq, locationsModel, locationsParams } from 'services';

const baseUrl = `${apiUrls.strankApiUrl}/locations`;

export const getAllCountries = () => getReq(`${baseUrl}/countries`);

export const getLocations = async (options) => {
  const [res, err] = await getReq(`${baseUrl}/cities?${locationsParams(options)}`, {
    transformRequest: [
      (data, headers) => {
        delete headers.common.Authorization;
        return data;
      },
    ],
  });

  return [locationsModel(res), err];
};
