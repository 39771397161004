import { theme } from 'styles';

export const leftSubscriptionContainer = {
  height: 46,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.warning,
  position: 'relative',

  '.warning-icon': {
    marginRight: 8,
  },

  '.text': {
    fontWeight: 600,
    color: theme.gray800,
  },

  '.close-icon': {
    position: 'absolute',
    right: 12,
  },
};
