import { useRef, forwardRef, useImperativeHandle } from 'react';
import { pick } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { changeUserCurrency, useCurrencies } from '@oneecosystem/dealshaker-core';
import { Modal, Row, Col, Tab, Tabs } from 'components/ui';
import { modalContainer, itemContainer } from './styles';

const LocalizationModal = forwardRef((props, ref) => {
  const { getText, language, allEnvLanguages, changeLanguage } = useTranslations();
  const { selectedCurrency, fiatCurrencies } = useCurrencies((currenciesState) =>
    pick(currenciesState, ['selectedCurrency', 'fiatCurrencies']),
  );
  const modalRef = useRef();

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current?.open(),
    close: () => modalRef.current?.close(),
  }));

  const handleChangeLanguage = (lang) => {
    changeLanguage(lang?.code);
    modalRef.current.close();
  };

  const handleChangeCurrency = (currency) => {
    changeUserCurrency(currency?.code);
    modalRef.current.close();
  };

  return (
    <Modal large ref={modalRef} closeIcon css={modalContainer}>
      <Row className="modal-content">
        <Tabs>
          <Tab
            component={() => (
              <Row justify="flex-start">
                {allEnvLanguages?.map((el, ind) => (
                  <Col
                    xs={12}
                    md={6}
                    lg={3}
                    key={ind}
                    onClick={() => handleChangeLanguage(el)}
                    css={itemContainer(el?.code === language?.code)}>
                    <h5>{el.code?.toUpperCase()}</h5>
                    <p className="language-name">{el.name}</p>
                  </Col>
                ))}
              </Row>
            )}
            label={getText('languages')}
          />
          <Tab
            component={() => (
              <Row>
                {fiatCurrencies?.map((el, ind) => (
                  <Col
                    xs={12}
                    md={6}
                    lg={3}
                    key={ind}
                    onClick={() => handleChangeCurrency(el)}
                    css={itemContainer(el?.code === selectedCurrency?.code)}>
                    <h5>{el.code}</h5>
                    <p className="name">{el.name}</p>
                  </Col>
                ))}
              </Row>
            )}
            label={getText('currencies')}
          />
        </Tabs>
      </Row>
    </Modal>
  );
});

export default LocalizationModal;
