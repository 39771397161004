import { useHistory } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components';
import { ROUTES } from 'enums';
import { ReactComponent as SuccessBooking } from 'assets/images/success-booking.svg';
import { successfulPaymentContainer } from './styles';

const SuccessCheckout = () => {
  const { getText } = useTranslations();
  const history = useHistory();

  return (
    <div css={successfulPaymentContainer}>
      <SuccessBooking className="image" />
      <div className="hotel-info">
        <h3 className="title">{getText('paymentSuccessful')}</h3>
        <p className="subtitle">{getText('thankForChoosingFindBookingDetails')}</p>
        <div className="actions">
          <Button small type="secondary" onClick={() => history.push(ROUTES.Hotels)} className="confirm">
            {getText('backHome')}
          </Button>
          <Button small onClick={() => history.push(ROUTES.MyBookings)}>
            {getText('viewBooking')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessCheckout;
