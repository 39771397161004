import { startCase, sample, isNil } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Image, Button, Form, Input } from 'components';
import { TRAVELOPRO_ADULTS_TITLES, TRAVELOPRO_CHILDS_TITLES } from 'enums';
import { useUserStore } from 'stores';
import avatarLogo from 'assets/images/avatar.svg';
import { guestFromContainer } from './styles';

const GuestsForm = ({ hotelId, filters, formData, onCompleted, onSetGuests }) => {
  const { getText } = useTranslations();
  const user = useUserStore();

  const userAdult = [{ firstName: user?.userInfo?.firstName, lastName: user?.userInfo?.lastName, isOwner: true }];
  const otherAdults = filters?.adults && Array(filters?.adults - 1)?.fill([{ firstName: '', lastName: '' }]);
  const allChilds = filters?.children && Array(filters?.children)?.fill([{ firstName: '', lastName: '' }]);
  const maxGuests = filters?.adults + filters?.children;
  const allGuests = {
    adults: otherAdults && [...userAdult, ...otherAdults]?.flat(),
    childs: allChilds && allChilds?.flat(),
  };
  const adultTitles = [TRAVELOPRO_ADULTS_TITLES.MR, TRAVELOPRO_ADULTS_TITLES.MRS, TRAVELOPRO_ADULTS_TITLES.MISS];
  const childTitles = [TRAVELOPRO_CHILDS_TITLES.MASTER, TRAVELOPRO_CHILDS_TITLES.MISS];

  const guests = (array, isChild) =>
    array &&
    array?.map((item) => ({
      title: sample(isChild ? childTitles : adultTitles),
      firstName: item.firstName,
      lastName: item.lastName,
    }));

  const handleSubmit = (val) => {
    const allAdults =
      val?.adult?.filter((el) => !isNil(el))?.length === filters?.adults
        ? val.adult
          ? [...val.adult]?.filter((el) => !isNil(el))
          : userAdult
        : val?.adult
        ? [...userAdult, ...val.adult]?.filter((el) => el?.firstName && el?.lastName)
        : userAdult;

    onCompleted({
      guests: {
        adults: isNil(allAdults) ? guests(userAdult) : guests(allAdults),
        childs: val?.child ? guests(val?.child, true) : [],
      },
      hotelId,
      checkIn: filters?.checkIn,
      checkOut: filters?.checkOut,
    });

    onSetGuests((prev) => ({
      ...prev,
      guests: {
        adult: isNil(allAdults) ? guests(userAdult) : guests(allAdults),
        child: val?.child ? guests(val?.child, true) : [],
      },
    }));
  };

  return (
    <div css={guestFromContainer}>
      <h4 className="room-title">{getText('maxGuestInBooking', { count: maxGuests })}</h4>
      <Form values={formData} onSubmit={(val) => handleSubmit(val)}>
        <h5 className="room-title">{startCase(getText('allAdults', { count: filters?.adults }))}</h5>
        {allGuests?.adults?.map((adult, adultIndex) => (
          <div key={`adult-${adultIndex}`}>
            {adult?.isOwner ? (
              <div key={`guest-${adultIndex}`} className="user-info">
                <Image src={avatarLogo} className="avatar-image" />
                <h5>{`${adult?.firstName} ${adult?.lastName} (${getText('you')})`}</h5>
              </div>
            ) : (
              <div key={`guest-${adultIndex}`} className="guest-container">
                <Input required formId={`adult[${adultIndex}].firstName`} label={getText('firstName')} />
                <Input required formId={`adult[${adultIndex}].lastName`} label={getText('lastName')} />
              </div>
            )}
          </div>
        ))}
        {!!allGuests?.childs?.length && (
          <h5 className="room-title">{startCase(getText('allChilds', { count: filters?.children }))}</h5>
        )}
        {!!allGuests?.childs?.length &&
          allGuests?.childs?.map((_, childIndex) => (
            <div key={`adult-${childIndex}`}>
              <div key={`guest-${childIndex}`} className="guest-container">
                <Input required formId={`child[${childIndex}].firstName`} label={getText('firstName')} />
                <Input required formId={`child[${childIndex}].lastName`} label={getText('lastName')} />
              </div>
            </div>
          ))}

        <div className="action-button">
          {({ onSubmit }) => <Button onClick={onSubmit}>{getText('nextStep')}</Button>}
        </div>
      </Form>
    </div>
  );
};

export default GuestsForm;
