import moment from 'moment';
import { isNil } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Col, Icon, Row } from 'components';
import { validDate } from 'utils';
import { checksPetsContainer } from './styles';

const CheckInOutPetsCart = ({ hotelDetails }) => {
  const { getText } = useTranslations();

  return (
    <Row
      gap={12}
      css={checksPetsContainer(hotelDetails?.petFriendlyData?.enable, hotelDetails?.petFriendlyData?.policy === '')}>
      {validDate(hotelDetails?.checkInTime ?? hotelDetails?.checkIn) &&
        validDate(hotelDetails?.checkOutTime ?? hotelDetails?.checkOut) && (
          <Col md={12} lg={6} xl={6}>
            <div className="checks-gray-container">
              {validDate(hotelDetails?.checkInTime ?? hotelDetails?.checkIn) && (
                <div>
                  <Icon material iconName="login" className="checks-icon" size={30} />
                  <h5>{getText('checkIn')}</h5>
                  <p className="check-date">{`${moment(hotelDetails?.checkInTime ?? hotelDetails?.checkIn)?.format(
                    'DD.MM.YYYY',
                  )}`}</p>
                  {/* REMOVE TAG REMOVE TIME FOR CHECKIN TIME
            <p className="check-date">{`${getText('after')} ${moment(
              hotelDetails?.checkInTime ?? hotelDetails?.checkIn,
            )?.format('LT')}`}</p> */}
                </div>
              )}

              {validDate(hotelDetails?.checkInTime ?? hotelDetails?.checkIn) && <div className="vertical-line" />}

              {validDate(hotelDetails?.checkOutTime ?? hotelDetails?.checkOut) && (
                <div>
                  <Icon material iconName="logout" className="checks-icon" size={30} />
                  <h5>{getText('checkOut')}</h5>
                  <p className="check-date">{`${moment(hotelDetails?.checkOutTime ?? hotelDetails?.checkOut)?.format(
                    'DD.MM.YYYY',
                  )}`}</p>
                  {/* REMOVE TAG REMOVE TIME FOR CHECKOUT TIME
            <p className="check-date">{`${getText('before')} ${moment(
              hotelDetails?.checkOutTime ?? hotelDetails?.checkOut,
            )?.format('LT')}`}</p> */}
                </div>
              )}
            </div>
          </Col>
        )}

      {!isNil(hotelDetails?.petFriendlyData) && (
        <Col md={12} lg={6} xl={6}>
          <div className="checks-pets-container">
            <Icon material iconName="sound_detection_dog_barking" className="checks-icon" size={30} color="black" />
            <h5 className="title">{getText('pets')}</h5>
            {hotelDetails?.petFriendlyData?.policy !== '' && (
              <p>{`${getText(`${hotelDetails?.petFriendlyData?.enable ? 'allowed' : 'notAllowed'}`)}`}</p>
            )}
            <p className="description">
              {hotelDetails?.petFriendlyData?.policy === ''
                ? `${getText('notHavePetsInformation')}`
                : hotelDetails?.petFriendlyData?.policy}
            </p>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default CheckInOutPetsCart;
