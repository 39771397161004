import { theme } from 'styles';

export const myProfileContainer = {
  position: 'relative',
  height: '100%',

  '.subscription-banner': {
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
  },

  '.frame-container': {
    background: `linear-gradient(360deg, ${theme.infoLighter} 0%,${theme.secondaryLightDarker} 100%)`,
    width: '100%',
    height: 180,
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,

    '.right-bottom-frame-image': {
      maxWidth: 130,
      maxHeight: 85,
      position: 'absolute',
      right: 0,
      bottom: -50,
    },

    '.left-bottom-frame-image': {
      maxWidth: 130,
      maxHeight: 120,
      position: 'absolute',
      left: 0,
      bottom: -50,
    },

    '.wallets-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '32px 128px 24px 200px',

      '.wallet-cart-container': {
        display: 'flex',
        flexDirection: 'row',

        '.wallet-cart': {
          marginRight: 16,

          '&:last-child': {
            marginRight: 0,
          },
        },
      },
    },
  },

  '.container': {
    position: 'relative',
    display: 'flex',
    padding: '0 128px 0 200px',
    justifyContent: 'space-between',
    width: '100%',

    '.user-content': {
      zIndex: 10,
      marginTop: -20,
    },

    '.user-languages-container': {
      zIndex: 10,
      padding: '40px 20px',

      '.title': {
        fontWeight: 500,
        marginBottom: 12,
      },

      '.language-cart': {
        borderRadius: '10px 10px 0 0',

        '@media (max-width: 576px)': {
          flexWrap: 'wrap',
        },
      },

      '.currency-cart': {
        borderRadius: '0 0 10px 10px ',
        marginBottom: 32,

        '@media (max-width: 576px)': {
          flexWrap: 'wrap',
        },
      },
    },

    '@media (max-width: 992px)': {
      padding: '0 64px 0 128px',
    },
  },
};
