import { theme } from 'styles';

export const checkoutSummaryContainer = {
  borderRadius: 10,
  border: `1px solid ${theme.gray150}`,
  marginBottom: 32,

  '.hotel-image': {
    height: 100,
    maxWidth: 120,
    borderRadius: 6,
    marginRight: 24,

    '@media (max-width: 576px)': {
      marginBottom: 24,
      width: '100%',
      maxWidth: 300,
    },
  },

  '.hotel-info': {
    display: 'flex',
    flexDirection: 'row',
    padding: 24,

    '.hotel-info-container': {
      '.hotel-title': {
        marginBottom: 8,
      },

      '.location': {
        color: theme.gray300,
        marginBottom: 8,
      },
    },

    '@media (max-width: 576px)': {
      flexDirection: 'column',
    },
  },

  '.horizontal-container': {
    backgroundColor: theme.gray50,
    height: 7,
  },

  '.room-info': {
    padding: 24,

    '.room-title': {
      marginBottom: 8,
    },

    '.square-footage': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme.gray300,
      fontWeight: 500,
      marginBottom: 8,
    },

    '.room-types': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme.gray400,
      fontWeight: 500,

      '.room-desc': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },

      '.dot': {
        width: 5,
        height: 5,
        backgroundColor: theme.gray150,
        borderRadius: 50,
        margin: '0 8px',
      },
    },

    '.amenities': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme.successDark,

      '.button': {
        padding: 0,

        '&:active': { outline: 'none' },

        '.action': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          color: theme.blue,
          fontWeight: 600,
        },
      },

      '.amenity-name': {
        fontSize: 12,
        fontWeight: 500,
        marginRight: 8,
      },
    },

    '.room-image': {
      height: 176,
      borderRadius: 10,
    },
  },

  '.search-info': {
    padding: 24,

    '.date-adults': {
      color: theme.gray800,
    },

    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '24px 0',
      borderBottom: `1px solid ${theme.gray150}`,

      '&:last-child': {
        borderBottom: 'none',
      },

      '& > p': {
        color: theme.gray800,
      },
    },
  },
};
