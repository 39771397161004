import { theme } from 'styles';

export const checkoutContainer = {
  height: '100%',

  '.batch': {
    backgroundColor: theme.gray700,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.white,
    padding: '12px 64px',

    '@media (max-width: 576px)': {
      flexWrap: 'wrap',
    },
  },

  '.secure-batch': {
    position: 'absolute',
    top: 15,
    left: '20%',
    zIndex: 1000,
    color: theme.successDark,
    backgroundColor: theme.successLighter,
    padding: '4px 12px',
    borderRadius: 50,
    fontSize: 12,

    '@media (max-width: 992px)': {
      position: 'static',
      width: 'fit-content',
      padding: 8,
      margin: 8,
      marginLeft: 64,
    },
  },

  '.stepper': {
    '.step-container': {
      position: 'absolute',
      top: 15,
      right: '40%',
      zIndex: 1000,

      '@media (max-width: 767px)': {
        position: 'static',
      },
    },
  },
};

export const checkoutReviewContainer = {
  height: '100%',

  '.title': {
    padding: '36px 64px',
    backgroundColor: theme.gray50,
  },

  '.summary-price-container': {
    width: '100%',
    padding: '32px 128px',

    '& * h4': {
      fontWeight: 500,
    },

    '.subtitle': {
      marginBottom: 16,
    },

    '.refund-container': {
      backgroundColor: theme.secondary,
      padding: 24,
      borderRadius: 10,
      marginBottom: 32,

      '& > h5': {
        color: theme.gray800,
        marginBottom: 12,
      },

      '& > p': {
        color: theme.gray400,
      },
    },

    '.guests-title': {
      marginBottom: 8,
    },

    '.guests-subtitle': {
      fontSize: 15,
      color: theme.gray400,
      marginBottom: 16,
    },

    '@media (max-width: 992px)': {
      padding: '32px 32px 32px 100px',
    },
  },
};

export const bedsContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

export const checkoutPaymentContainer = {
  '.payment-title': {
    padding: '36px 128px',
    backgroundColor: theme.gray50,
  },

  '.wallets-container': {
    marginTop: 0,
    padding: 0,
  },

  '.payments-container': {
    padding: '32px 128px',
    width: '100%',

    '.action-container': {
      display: 'flex',
      justifyContent: 'space-between',

      '.icon': {
        marginRight: 4,
      },
    },

    '@media (max-width: 992px)': {
      padding: '32px 32px 32px 100px',
    },
  },
};
