import { useRef } from 'react';
import moment from 'moment';
import { useTranslations } from '@veraio/strank';
import { Image, Icon, Button, RatingReviewStars, AmenitiesModal } from 'components';
import { bedTypesMap } from './config';
import { checkoutSummaryContainer } from './styles';

const CheckoutSummary = ({ roomDetails, hotelDetails, filters, days }) => {
  const { getText } = useTranslations();
  const amenitiesModalRef = useRef();

  return (
    <>
      <div css={checkoutSummaryContainer}>
        <div className="hotel-info">
          <Image src={hotelDetails?.hotelImages[0]} className="hotel-image" />
          <div className="hotel-info-container">
            <RatingReviewStars hotel={hotelDetails} withoutStars />

            {hotelDetails?.name && <h4 className="hotel-title">{hotelDetails?.name}</h4>}
            <div className="location">
              {hotelDetails?.address?.address && <span>{`${hotelDetails?.address?.address}`}</span>}
              {hotelDetails?.address?.cityName && <span>{`, ${hotelDetails?.address?.cityName}`}</span>}
              {hotelDetails?.address?.stateName && <span>{`, ${hotelDetails?.address?.stateName}`}</span>}
              {hotelDetails?.address?.countryName && <span>{`, ${hotelDetails?.address?.countryName}`}</span>}
            </div>
          </div>
        </div>
        <div className="horizontal-container" />
        <div className="room-info">
          {roomDetails?.title && <h4 className="room-title">{roomDetails?.description}</h4>}
          {roomDetails?.squareFootage > 0 && (
            <div className="square-footage">
              <Icon material iconName="fullscreen" color="gray300" className="icon-room" />
              <p>{getText('squareFootage', { count: roomDetails?.squareFootage })}</p>
            </div>
          )}
          <div className="room-types">
            {roomDetails?.beds?.map((el, ind) => (
              <div key={ind} className="room-desc">
                {`${el?.count} x `}
                {bedTypesMap(getText)[el?.type]}
                <div className="dot" />
              </div>
            ))}
          </div>
          {roomDetails?.amenities?.length > 0 && (
            <div className="amenities">
              <Button small type="link" onClick={() => amenitiesModalRef.current?.open()} className="button">
                <div className="action">
                  {getText('amenities')}
                  <Icon material iconName="keyboard_arrow_right" className="icon" />
                </div>
              </Button>
            </div>
          )}
          <Image
            src={roomDetails?.images ? roomDetails?.images[0] : hotelDetails?.hotelImages[0]}
            className="room-image"
          />
        </div>
        <div className="horizontal-container" />
        <div className="search-info">
          <div>
            <h5>{getText('dates')}</h5>
            <p className="date-adults">{`${moment(filters?.checkIn).format('D')} - ${moment(filters?.checkOut).format(
              'D',
            )} ${moment(filters?.checkIn).format('MMM')} (${days} ${getText('nights')})`}</p>
          </div>
          <div>
            <h5>{getText('guestsAndRooms')}</h5>
            <p className="date-adults">
              {filters?.adults > 0 && `${filters?.adults} ${getText('adults').toLowerCase()} | `}
              {filters?.children > 0 && `${filters?.children} ${getText('children').toLowerCase()} | `}
              {filters?.rooms > 0 && ` ${filters?.rooms} ${getText('rooms').toLowerCase()}`}
            </p>
          </div>
        </div>
      </div>
      <AmenitiesModal ref={amenitiesModalRef} amenities={roomDetails?.amenities} />
    </>
  );
};

export default CheckoutSummary;
