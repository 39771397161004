import { theme } from 'styles';

export const detailsContainer = {
  padding: '0 84px',
  height: '100%',

  '.gallery': {
    '.image-gallery-slides': {
      maxHeight: 360,
      width: '100%',
      objectFit: 'contain',
    },

    '.first-image': {
      height: 364,
      borderRadius: '10px 0 0 10px',
    },

    '.second-image': {
      height: 180,
      borderRadius: '0 10px 0 0',
    },

    '.last-image': {
      height: 180,
      borderRadius: '0 0 10px 0',
    },
  },

  '.container': {
    marginBottom: 32,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    '.margin': {
      marginBottom: 4,
      marginTop: 32,
    },

    '.title': {
      marginBottom: 4,
    },

    '.location': {
      color: theme.gray500,
      marginBottom: 8,
      fontSize: 14,
    },

    '.entertainment-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '.entertainment-badge': {
        borderRadius: 25,
        marginRight: 8,

        '.badge-container': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
      },

      '.tooltip-container': {
        backgroundColor: theme.infoLight,
        padding: '4px 12px',
        borderRadius: 8,
      },
    },
  },
};

export const tooltipContent = {
  '& .tooltip-content': {
    fontSize: 10,
    borderRadius: 40,
    padding: '8px 16px',
  },
};

export const tabsStyle = {
  '& .tabs-list-container': {
    borderBottom: `1px solid ${theme.gray150}`,
  },
};

export const overviewContainer = (showAllDescription) => ({
  '.titles': {
    marginBottom: 24,
  },

  '.title-amenity': {
    margin: '32px 0',
  },

  '.reviews': {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 32,
    width: '100%',

    '.review-info': {
      '.review-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.successDark,
        marginBottom: 24,

        '.verified-icon': {
          marginRight: 4,
        },
      },

      '.review-rating-info': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: theme.gray100,
        padding: 24,
        borderRadius: 12,
        maxWidth: 300,

        '.rating-number': {
          fontSize: 56,
          marginRight: 16,
        },

        '.score-desc': {
          marginBottom: 8,
        },
      },
    },
  },

  '.loading-map': {
    marginBottom: 32,
  },

  '.overview-desc-deal': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '.desc-container': {
      width: '100%',

      '.description': {
        flex: 1,
        color: theme.gray300,
        fontSize: 16,
        lineHeight: 1.5,
        maxHeight: showAllDescription ? '100%' : 170,
        display: 'block',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
        marginRight: 32,
      },

      '.action-button': {
        color: theme.infoDark,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 0,
      },

      '@media (max-width: 767px)': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },

  '.amenity': {
    padding: '16px 24px',
    backgroundColor: theme.gray100,
    borderRadius: 10,
    minWidth: 170,

    '& > div:first-of-type': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',

      '& > div': {
        marginBottom: '16px !important',
      },
    },
  },

  '.amenity-container': {
    display: 'flex',
    flexDirection: 'row',

    '.margin': {
      marginRight: 16,
    },
  },

  '.policies': {
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    '.policy-cart': {
      borderBottom: 'none',
      width: '100%',

      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
});

export const maps = {
  marginBottom: 32,
  borderRadius: 20,
};

export const availabilityContainer = {
  '.title': {
    marginBottom: 32,
  },

  '.not-have-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 48,

    '.not-have-title': {
      color: theme.gray800,
      fontWeight: 500,
    },

    '.not-have-subtitle': {
      color: theme.gray700,
    },

    '& > svg': {
      maxHeight: 300,
    },
  },
};
