import { theme } from 'styles';

export const bookingCardContainer = {
  padding: 16,
  backgroundColor: theme.gray100,
  display: 'flex',
  flexDirection: 'row',
  borderRadius: 10,
  marginBottom: 32,

  '&:last-child': {
    marginBottom: 0,
  },

  '.booking-image': {
    borderRadius: 10,
    marginRight: 20,
    width: 330,
    minWidth: 300,
    height: 170,
    objectFit: 'fill',

    '@media (max-width: 992px)': {
      minWidth: 200,
    },

    '@media (max-width: 767px)': {
      minWidth: '100%',
      objectFit: 'cover',
    },
  },

  '.booking-container': {
    width: '100%',
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '.booking-badge': {
      borderRadius: 20,
      marginBottom: 8,
    },

    '.hotel-title': {
      marginBottom: 8,
    },

    '.location': {
      color: theme.gray400,

      '@media (max-width: 576px)': {
        marginBottom: 8,
      },
    },

    '.dates-button-container': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

      '.icon-dates': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '.calendar-icon': {
          marginRight: 4,
        },

        '.dates': {
          color: theme.gray400,
        },
      },

      '.booking-details-button': {
        color: theme.blue,
        fontWeight: 600,
        padding: 0,

        '&:hover': {
          borderColor: 'none',
        },

        '&:active': { outline: 'none' },
      },

      '@media (max-width: 992px)': {
        flexWrap: 'wrap',
      },
    },
  },

  '@media (max-width: 767px)': {
    flexWrap: 'wrap',
  },
};
