import airportLounge from 'assets/images/airport-lounge-logo.svg';
import groupBookingConcierge from 'assets/images/group-booking-concierge-logo.svg';
import priceGuarantee from 'assets/images/price-guarantee-logo.svg';

export const memberBenefits = (getText) => [
  {
    image: airportLounge,
    title: getText('airportLoungeAccess'),
    subtitle: getText('airportLoungeAccessDescription'),
    description: getText('airportLoungeAccessAllDescription'),
  },
  {
    image: priceGuarantee,
    title: getText('priceGuarantee'),
    subtitle: getText('priceGuaranteeDescription'),
    description: getText('priceGuaranteeAllDescription'),
  },
  {
    image: groupBookingConcierge,
    title: getText('groupBookingConcierge'),
    subtitle: getText('groupBookingConciergeDescription'),
    description: getText('groupBookingConciergeAllDescription'),
  },
];
