import { useState } from 'react';
import PropTypes from 'prop-types';
import { camelCase, capitalize, isNil } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Icon, Row, Col, Button, Skeleton } from 'components';
import { amenitiesContainer } from './styles';

const AmenityCart = (props) => {
  const { amenities } = props;
  const { getText } = useTranslations();
  const [showMore, setShowMore] = useState(false);

  const allAmenities = showMore || amenities?.length < 20 ? amenities : amenities?.slice(0, 20);

  return (
    <div css={amenitiesContainer}>
      <Row className="row-container">
        {isNil(allAmenities)
          ? Array(20)
              .fill()
              .map((_, i) => (
                <Col xs={12} sm={6} md={4} lg={3} key={i} className="amenity-cart">
                  <Skeleton key={i} width="100%" height={30} />
                </Col>
              ))
          : allAmenities?.map((el, ind) => (
              <Col xs={12} sm={6} md={4} lg={3} key={ind} className="amenity-cart">
                <Icon material iconName="done" color="success" className="icon" />
                <p>{capitalize(getText(camelCase(el.name)))}</p>
              </Col>
            ))}
      </Row>
      {amenities?.length > 20 && (
        <Button small type="link" onClick={() => setShowMore(!showMore)} className="action-container">
          <div>
            <Icon
              material
              iconName={!showMore ? 'expand_circle_down' : 'expand_circle_up'}
              size={32}
              className="icon"
            />
            {!showMore ? getText('viewAll') : getText('viewLess')}
          </div>
        </Button>
      )}
    </div>
  );
};

AmenityCart.propTypes = {
  amenities: PropTypes.array,
};

export default AmenityCart;
