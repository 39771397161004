import { isEmpty, isString } from 'lodash-es';
import queryString from 'query-string';

export const locationsModel = (response) => response?.results;

export const locationsParams = (options = {}) => {
  const { name } = options;

  return queryString.stringify(
    {
      key: 'dgb7TgC5zR0YpsAqbEgb',
      ...((!isEmpty(name) || isString(name)) && { name }),
    },
    { arrayFormat: 'index' },
  );
};
