import { theme } from 'styles';

export const ratingReviewStarsContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '.dot': {
    width: 4,
    height: 4,
    backgroundColor: theme.gray200,
    borderRadius: 50,
  },

  '& > *': {
    marginRight: 8,
  },

  '.guest-rating': {
    padding: '4px 12px',
    backgroundColor: theme.primary,
    borderRadius: 25,
  },

  '.reviews': {
    color: theme.gray300,
  },

  '.hotel-stars': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.gray300,
  },
};
