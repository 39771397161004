import { theme } from 'styles';

export const subscriptionsContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',

  '.subscriptions-badge-container': {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 16px',
    overflowX: 'hidden',

    '.column-cart': {
      display: 'flex',
    },
  },

  '.arrow': {
    color: theme.gray150,
  },
};
