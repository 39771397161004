import { useHistory } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { ColorBatch, Icon, Image, Button } from 'components';
import { replacePathParams } from 'utils';
import { bookingStatusMap } from './config';
import { bookingCardContainer } from './styles';

const BookingCart = (props) => {
  const { bookingInfo, bookingStatus } = props;
  const { getText } = useTranslations();
  const history = useHistory();

  return (
    <div css={bookingCardContainer}>
      <Image src={bookingInfo?.hotelImage} className="booking-image" />
      <div className="booking-container">
        <div>
          <ColorBatch type={bookingStatusMap[bookingInfo?.status]?.color} className="booking-badge">
            {getText(`${camelCase(bookingInfo?.status)}`)}
          </ColorBatch>
          {bookingInfo?.hotelName && <h4 className="hotel-title">{bookingInfo?.hotelName}</h4>}
          {bookingInfo?.address && (
            <div className="location">
              {bookingInfo?.address?.address && <span>{`${bookingInfo?.address?.address}`}</span>}
              {bookingInfo?.address?.cityName && <span>{`, ${bookingInfo?.address?.cityName}`}</span>}
              {bookingInfo?.address?.stateName && <span>{`, ${bookingInfo?.address?.stateName}`}</span>}
              {bookingInfo?.address?.countryName && <span>{`, ${bookingInfo?.address?.countryName}`}</span>}
            </div>
          )}
        </div>
        <div className="dates-button-container">
          <div className="icon-dates">
            <Icon material iconName="calendar_month" color="gray500" className="calendar-icon" />
            <p className="dates">{`${moment(new Date(bookingInfo?.checkIn)).format('D MMM')} - ${moment(
              new Date(bookingInfo?.checkOut),
            ).format('D MMM')} (${bookingInfo?.nights} ${getText('nights')})`}</p>
          </div>
          <Button
            small
            type="link"
            className="booking-details-button"
            onClick={() =>
              history.push({
                pathname: replacePathParams('/bookings/:bookingStatus/:bookingId', {
                  bookingStatus: bookingInfo?.status,
                  bookingId: bookingInfo?.bookingId,
                }),
                state: { bookingInfo, bookingStatus },
              })
            }>
            {getText('details')}
            <Icon material iconName="chevron_right" className="icon-expand" />
          </Button>
        </div>
      </div>
    </div>
  );
};

BookingCart.propTypes = {
  bookingInfo: PropTypes.object,
  bookingStatus: PropTypes.string,
};

export default BookingCart;
