import { capitalize, isArray, isNil, isPlainObject } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { loginWithPassword } from '@oneecosystem/authenticate';
import { Row, Col, Button, showError, Form, Input, inputValidation, Image } from 'components';
import { ROUTES } from 'enums';
import { ReactComponent as Logo } from 'assets/images/logo-horizontal.svg';
import appStore from 'assets/images/app-store.png';
import googlePlay from 'assets/images/google-play.png';
import { publicContentContainer } from './styles';

const Login = ({ history, location }) => {
  const { getText } = useTranslations();
  const { state } = location;

  const handleLogin = async (data) => {
    const res = await loginWithPassword(data);
    const err =
      // Error from our api
      res?.errorDescription ||
      // Keycloak error
      (isArray(res) && res[0]?.message && 'Incorrect username or password') ||
      // Error from sts identity
      (isPlainObject(res?.errors) && Object.values(res.errors).map((el) => el[0]));
    //     Error from sts identity                  Default behavior
    if (err) return isArray(err) ? err.forEach(showError) : showError(err);

    if (state?.navigateTo) history.replace({ pathname: state.navigateTo, state: state?.item });

    if (!err && isNil(state?.navigateTo)) history.replace('/');

    return [res, err];
  };

  return (
    <Row css={publicContentContainer}>
      <Col sm={12} md={6} lg={7} className="action-column">
        <Logo onClick={() => history.push('/')} className="logo" />

        <Form onSubmit={handleLogin} className="form-container">
          <h1 className="title">{getText('logIn')}</h1>
          <p className="desc">{getText('startExploringHundredsOfDestinations')}</p>
          <Input
            required
            formId="email"
            margin="0 0 1em"
            label={getText('emailAddress')}
            validate={inputValidation('required', 'email')}
          />
          <Input
            required
            type="password"
            formId="password"
            margin="0 0 1em"
            label={getText('password')}
            validate={inputValidation('required', 'password')}
          />

          <Button type="link" linkTo={ROUTES.ForgotPassword} className="forgot-button">
            {getText('forgotPassword')}
          </Button>

          <div className="action-container">
            {({ onSubmit }) => (
              <Button small onClick={onSubmit} className="login-button">
                {getText('logIn')}
              </Button>
            )}
          </div>
        </Form>
        <div>
          <p className="paragraph">
            {`${getText('byContinuingYouAgree')} `}
            <Button type="link" onClick={() => history.push(ROUTES.TermsAndConditions)} className="link-button">
              <span className="underline-text">{getText('termsOfService')}</span>
            </Button>{' '}
            {getText('and')}{' '}
            <Button type="link" onClick={() => history.push(ROUTES.PrivacyPolicy)} className="link-button">
              <span className="underline-text">{capitalize(getText('privacyPolicy'))}.</span>
            </Button>
          </p>
          <p className="paragraph"> {getText('ifDoNotHaveAccountCreate')}</p>
        </div>
        <div className="info-container">
          <h5 className="text-app">{getText('getOneVoyageApp')}</h5>
          <div>
            <Button small type="link" linkTo={ROUTES.AppStore} className="button">
              <Image src={appStore} linkTo={ROUTES.AppStore} className="button" />
            </Button>
            <Button small type="link" linkTo={ROUTES.GoogleStore} className="button">
              <Image src={googlePlay} linkTo={ROUTES.GoogleStore} className="button" />
            </Button>
          </div>
        </div>
      </Col>
      <Col sm={12} md={6} lg={5} className="pictureContainer">
        <div className="picture" />
      </Col>
    </Row>
  );
};

export default Login;
