import { theme } from 'styles';

export const modalContainer = {
  '.title': {
    margin: '16px 0',
    textAlign: 'center',
    color: theme.black,
  },

  '.date': {
    color: theme.gray400,
    textAlign: 'center',
  },

  '.badges': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 24,

    '.stars-badge': {
      marginRight: 8,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },

  '.colors-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '.green': {
      backgroundColor: theme.successLight,
      width: 100,
      height: 6,
      marginRight: 4,
    },
    '.yellow': {
      backgroundColor: theme.warningLight,
      width: 150,
      height: 6,
      marginRight: 4,
    },
    '.red': {
      backgroundColor: theme.error,
      width: 100,
      height: 6,
    },

    '.none': {
      color: theme.white,
    },

    '.right': {
      textAlign: 'right',
      color: theme.black,
    },

    '.left': {
      textAlign: 'left',
      color: theme.black,
    },
  },

  '.text': {
    color: theme.black,
    margin: '12px 0',
  },
};

export const tooltipContainer = {
  display: 'block',
};

export const tooltipContent = {
  '& .tooltip-content': {
    display: 'flex',
    flexDirection: 'row',
    padding: '0.6em 1.4em',
    backgroundColor: theme.highlight,
    boxShadow: 'none',
    marginLeft: 32,
    borderRadius: 6,
    width: 'auto',
    marginBottom: 6,
  },
};
