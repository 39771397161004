import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { div, mul } from 'exact-math';
import { camelCase, intersectionBy } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Col, ColorBatch, Icon, useUrlParams } from 'components';
import { amenitiesContainer, amenityBadge } from './styles';

const AmenitiesSlider = (props) => {
  const { hotelAmenities, withIcon = false, onlyShow = false, onApplyFilters, batchClassName } = props;
  const { getText } = useTranslations();
  const { queryParams, setQueryParams } = useUrlParams();
  const { amenities = [] } = queryParams;
  const [selectedAmenities, setSelectedAmenities] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollContainerRef = useRef();
  const cardRef = useRef();
  const showCartInContainer = div(scrollContainerRef.current?.clientWidth ?? 0, cardRef.current?.clientWidth ?? 0);
  const cart = Math.floor(showCartInContainer);
  const allSelectedAmenities = intersectionBy(
    hotelAmenities,
    selectedAmenities?.map((el) => ({ id: el })),
    'id',
  );
  const hotelAmenitiesWithoutSelected = hotelAmenities?.filter((item) => !allSelectedAmenities?.includes(item));

  useEffect(() => {
    scrollContainerRef.current?.scrollTo({
      left: mul(currentIndex, cardRef.current?.clientWidth ?? 0),
      behavior: 'smooth',
      top: 0,
    });
  }, [currentIndex]);

  useEffect(() => {
    selectedAmenities?.length >= 0 && onApplyFilters({ ...queryParams, amenities: selectedAmenities });
  }, [selectedAmenities?.length]);

  const handleSelectAmenity = (value) => {
    if (onlyShow) return;

    const amenity = amenities?.some((el) => el === value)
      ? amenities?.filter((el) => el !== value)
      : [...amenities, value];

    setSelectedAmenities(amenity);
    setQueryParams({ ...queryParams, amenities: amenity });
  };

  const handlePrevSlide = () =>
    setCurrentIndex((prevIndex) => (prevIndex !== 0 ? prevIndex - 1 : hotelAmenities.length - cart));

  const handleNextSlide = () =>
    setCurrentIndex((prevIndex) => (prevIndex !== hotelAmenities.length - cart ? prevIndex + 1 : 0));

  return (
    <div css={amenitiesContainer(hotelAmenities?.length <= 6)}>
      {hotelAmenities?.length > 6 && currentIndex !== 0 && (
        <Icon material size={30} onClick={handlePrevSlide} iconName="arrow_circle_left" className="arrow" />
      )}

      <div className="amenities-badge-container" ref={scrollContainerRef}>
        {[...allSelectedAmenities, ...hotelAmenitiesWithoutSelected]?.map((el, ind) => (
          <Col xl={withIcon ? 2 : 1.5} lg={3} md={4} sm={6} xs={12} key={ind} className="badge" ref={cardRef} gap={12}>
            <ColorBatch
              withoutStartCase
              type="default"
              css={amenityBadge(
                amenities?.some((amenity) => amenity === el.id),
                onlyShow,
              )}
              {...(batchClassName && { className: batchClassName })}
              onClick={() => handleSelectAmenity(el.id)}>
              {withIcon ? (
                <div className="batch-container">
                  <Icon size={20} material iconName={el.icon} margin="0 4px 0 0" /> <p>{getText(camelCase(el.name))}</p>
                </div>
              ) : (
                getText(camelCase(el.name))
              )}
            </ColorBatch>
          </Col>
        ))}
      </div>

      {hotelAmenities?.length > 6 && hotelAmenities?.length - cart !== currentIndex && (
        <Icon material size={30} onClick={handleNextSlide} iconName="arrow_circle_right" className="arrow" />
      )}
    </div>
  );
};

AmenitiesSlider.propTypes = {
  hotelAmenities: PropTypes.array,
  withIcon: PropTypes.bool,
  onlyShow: PropTypes.bool,
  onApplyFilters: PropTypes.func,
  batchClassName: PropTypes.string,
};

export default AmenitiesSlider;
