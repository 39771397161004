import { Col, Row, Skeleton } from 'components';
import { cardContainer } from './styles';

const LoadingHotelCard = () =>
  Array(10)
    .fill()
    .map((_, i) => (
      <Row key={i} css={cardContainer}>
        <Col xs={12} sm={6} md={9} lg={9} className="hotel-info" displayFlex direction="row">
          <Skeleton width={300} height={216} />

          <div className="info">
            <div>
              <Skeleton width={100} height={22} className="entertainment-badge" />
              <Skeleton width={330} height={22} className="margin" />
              <Skeleton width={200} height={22} className="location" />
            </div>
            <div>
              <div className="amenities">
                <Skeleton width={100} height={22} className="amenities-info" />
              </div>
              <Skeleton width={330} height={22} />
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6} md={3} lg={3} className="price-info">
          <Skeleton width={100} height={22} />
          <Skeleton width={100} height={22} />
          <Skeleton width={100} height={22} />

          <div className="price">
            <Skeleton width={70} height={22} />
          </div>
          <Skeleton width={150} height={22} className="for-night" />
        </Col>
      </Row>
    ));

export default LoadingHotelCard;
