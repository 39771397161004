import { useTranslations } from '@veraio/strank';
import { Image, Button, Icon } from 'components';
import { useUserStore } from 'stores';
import avatarLogo from 'assets/images/avatar.svg';
import { userContentInformation } from './styles';

const UserCart = () => {
  const { getText } = useTranslations();
  const user = useUserStore();
  const oneLifeUrlAccounts = `${applicationUrls.lifeUrl}/profile/accounts`;

  const handleGoToOneLife = () => {
    window.location.href = oneLifeUrlAccounts;
  };

  return (
    <div css={userContentInformation}>
      <Image
        src={user?.userInfo?.pictureUrl ?? user?.userInfo?.picThumbnailUrl ?? avatarLogo}
        className="avatar-image"
      />
      <h4 className="name">{`${user?.userInfo?.firstName} ${user?.userInfo?.lastName}`}</h4>
      <p className="phone-email">{user?.userInfo?.phone}</p>
      <p className="phone-email">{user?.userInfo?.email}</p>
      <div className="external-button">
        <p className="text">{getText('toEditGoToYour')}</p>
        <Button type="link" onClick={handleGoToOneLife} className="button-life">
          <p className="button-text">{getText('oneLifeAccount')}</p>
          <Icon material iconName="ungroup" size={24} />
        </Button>
      </div>
    </div>
  );
};

export default UserCart;
