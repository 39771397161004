import { theme } from 'styles';

export const modalContainer = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  top: 0,
  minWidth: 500,

  '.title': {
    fontWeight: 500,
    fontSize: 22,
    marginBottom: 12,
    padding: '0 1em',
  },

  '.subtitle': {
    fontSize: 16,
    color: theme.gray300,
    padding: '0 1em',
  },

  '.horizontal-line': {
    backgroundColor: theme.gray200,
    margin: '12px 0 24px',
  },

  '& > div:first-of-type': {
    height: '100vh',
    padding: '2em 0',
  },

  '.action-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.25)',
    padding: 16,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    backgroundColor: theme.white,

    '.clear-button': {
      color: theme.blue,
      fontWeight: 600,
      fontSize: 16,
    },
  },

  '.scrollable-container': {
    height: 'calc(100vh - 80px)',
    overflow: 'auto',
    zIndex: 1,
    marginBottom: 80,

    '.section-title': {
      fontWeight: 500,
      fontSize: 18,
      color: theme.gray800,
      marginBottom: 16,
      padding: '0 1em',
    },

    '.kinds-container': {
      padding: '0 1em',

      '.item': {
        padding: '12px 16px',
        fontSize: 16,
      },
    },

    '.horizontal-section': {
      height: 8,
      backgroundColor: theme.gray50,
      borderBottom: `0.5px solid ${theme.gray150}`,
      borderTop: `0.5px solid ${theme.gray150}`,
      marginBottom: 24,
    },

    '.amount-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 24,
      padding: '0 2em',

      '.remove-icon': {
        margin: '0 1em',
      },

      '.amount-input': {
        '.input': {
          paddingTop: 8,
        },

        '.euro-symbol-styles': {
          top: '1.5rem',
        },
      },
    },

    '.type-container': {
      padding: '0 2em',
      color: theme.gray500,
      fontSize: 16,

      '.switch-content-container': {
        '.switch': {
          border: 'none',
          borderRadius: 0,

          '.input-indicator': {
            marginRight: 0,
          },
        },

        '.label-right': {
          marginLeft: 76,
        },
      },
    },
  },

  '.scrollable-container::-webkit-scrollbar': {
    display: 'none',
  },

  '@media (max-width: 767px)': {
    minWidth: 300,
  },
};
