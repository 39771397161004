import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components';
import { ROUTES } from 'enums';
import { footerContainer } from './styles';

const Footer = (props) => {
  const { className } = props;
  const getYear = new Date().getFullYear();
  const { getText } = useTranslations();
  const history = useHistory();

  return (
    <footer css={footerContainer} {...(className && { className })}>
      <p className="text">
        &nbsp; © {getYear} <span>OneVoyage, Inc. All Rights Reserved</span>
      </p>

      <div>
        <Button type="link" className="button">
          <a href="mailto: help@onevoyage.com" className="help-button">
            {getText('help')}
          </a>
        </Button>
        <Button type="link" onClick={() => history.push(ROUTES.PrivacyPolicy)} className="button">
          {getText('privacyPolicy')}
        </Button>
        <Button type="link" onClick={() => history.push(ROUTES.TermsAndConditions)} className="button">
          {getText('termsAndConditions')}
        </Button>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
