import { theme } from 'styles';

export const reviewCart = {
  '.creation-user': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,

    '.review-rating': {
      display: 'flex',
      alignItems: 'center',
      padding: '4px 8px',
      backgroundColor: theme.primary,
      borderRadius: 25,
      marginRight: 8,
    },

    '.desc': {
      marginRight: 8,
    },
  },

  '.comment': {
    fontSize: 16,
    color: theme.gray400,
    marginBottom: 8,
  },

  '.date-user-styles': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > p': {
      fontSize: 14,
      color: theme.gray500,
    },

    '.dot': {
      width: 4,
      height: 4,
      backgroundColor: theme.gray200,
      borderRadius: 50,
      margin: '0 8px',
    },
  },

  '.horizontal-line': {
    backgroundColor: theme.gray400,
    marginBottom: 32,
  },
};

export const reviewContainer = {
  width: '100%',
  '.all-review-button': {
    color: theme.black,
    fontWeight: 500,
  },
};
