import { theme } from 'styles';

export const amenitiesContainer = {
  position: 'relative',
  marginBottom: 48,

  '.row-container': {
    border: `1px solid ${theme.gray150}`,
    padding: 24,
    borderRadius: 16,

    '.amenity-cart': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 4,

      '.icon': {
        marginRight: 8,
      },
    },
  },

  '.action-container': {
    position: 'absolute',
    bottom: -35,
    right: '50%',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      '.icon': {
        backgroundColor: theme.white,
      },
    },
  },
};
