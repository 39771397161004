import { BOOKING_STATUSES } from 'enums';

export const bookingStatusMap = {
  [BOOKING_STATUSES.COMPLETED]: {
    color: 'success',
  },
  [BOOKING_STATUSES.REFUNDED]: {
    color: 'warning',
  },
  [BOOKING_STATUSES.PROCESSING_PAYMENT]: {
    color: 'info',
  },
  [BOOKING_STATUSES.PAYMENT_COMPLETED]: {
    color: 'pink',
  },
  [BOOKING_STATUSES.PAYMENT_FAILED]: {
    color: 'error',
  },
  [BOOKING_STATUSES.PROCESSING_REFUND]: {
    color: 'blue',
  },
  [BOOKING_STATUSES.REJECTED_BY_PROVIDER]: {
    color: 'red',
  },
};
