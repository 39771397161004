import { theme } from 'styles';

export const savedTravelersContainer = {
  borderRadius: 10,
  border: `0.5px solid ${theme.gray150}`,
  boxShadow: theme.boxShadowDark,
  backgroundColor: theme.white,
  padding: '32px 24px',
  marginBottom: 32,

  '.user': {
    padding: 24,
    backgroundColor: theme.gray100,
    borderRadius: 10,
    marginBottom: 24,

    '.avatar-image': {
      width: 24,
      height: 24,
      marginBottom: 12,
    },

    '.name': {
      fontSize: 16,
      fontWeight: 500,
    },
  },

  '.traveler-title': {
    fontWeight: 600,
    margin: '24px 0',
  },

  '.inputs-container': {
    display: 'flex',
    flexDirection: 'row',
  },

  '.buttons-container': {
    marginBottom: 24,

    '.save-button': {
      color: theme.gray800,
      fontWeight: 600,
    },

    '.cancel-button': {
      color: theme.gray800,
      fontWeight: 600,
      marginRight: 16,
    },
  },

  '.passenger-container': {
    marginBottom: 24,

    '.passengers': {
      padding: 24,
      backgroundColor: theme.gray100,
      borderRadius: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '&:last-child': {
        marginBottom: 0,
      },

      '.avatar-image': {
        width: 24,
        height: 24,
        marginBottom: 12,
      },

      '.name': {
        fontSize: 16,
        fontWeight: 500,
      },

      '.birth-date': {
        color: theme.gray400,
      },

      '.action-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '.edit-button': {
          color: theme.blue,
          fontWeight: 600,
          padding: 0,
          marginRight: 16,

          '&:hover': {
            borderColor: 'none',
            backgroundColor: 'none',
          },

          '&:active': { outline: 'none' },
        },

        '.delete-button': {
          padding: 0,

          '&:hover': {
            borderColor: 'none',
            backgroundColor: 'none',
          },

          '&:active': { outline: 'none' },
        },
      },
    },
  },

  '.traveler-button': {
    color: theme.gray800,
    fontWeight: 600,
  },
};

export const deletePassengerModalContainer = {
  '& > div:first-of-type': {
    '& > *': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 32,
    },

    '.delete-passenger-title': {
      fontWeight: 500,
      textAlign: 'center',
    },

    '.action-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 0,
    },
  },
};
