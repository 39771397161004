import { Icon } from 'components/ui';
import { HOTEL_BED_TYPES } from 'enums';
import { bedsContainer } from './styles';

export const bedTypesMap = (getText) => ({
  [HOTEL_BED_TYPES.KING]: (
    <div css={bedsContainer}>
      <Icon size={20} material iconName="king_bed" margin="0 4px 0 4px" /> <p>{getText('king')}</p>
    </div>
  ),
  [HOTEL_BED_TYPES.FULL]: (
    <div css={bedsContainer}>
      <Icon size={20} material iconName="bed" margin="0 4px 0 4px" /> <p>{getText('full')}</p>
    </div>
  ),
  [HOTEL_BED_TYPES.QUEEN]: (
    <div css={bedsContainer}>
      <Icon size={20} material iconName="king_bed" margin="0 4px 0 4px" /> <p>{getText('queen')}</p>
    </div>
  ),
  [HOTEL_BED_TYPES.SOFABED]: (
    <div css={bedsContainer}>
      <Icon size={20} material iconName="weekend" margin="0 4px 0 4px" /> <p>{getText('sofabed')}</p>
    </div>
  ),
  [HOTEL_BED_TYPES.TWIN]: (
    <div css={bedsContainer}>
      <Icon size={20} material iconName="single_bed" margin="0 4px 0 4px" /> <p>{getText('twin')}</p>
    </div>
  ),
});
