import { useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { capitalize, isNil, round } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Icon, Button, LoadingReviewCart, Modal } from 'components';
import { HOTEL_GUEST_SCORE } from 'enums';
import { reviewContainer, modalContainer, reviewCart } from './styles';

const ReviewCart = (props) => {
  const { reviews } = props;
  const { getText } = useTranslations();
  const modalRef = useRef();

  return (
    <>
      {!isNil(reviews) ? (
        <div css={reviewContainer}>
          {reviews?.slice(0, 3)?.map((el, ind) => (
            <div key={ind} css={reviewCart}>
              <div className="creation-user">
                <h5 className="review-rating">
                  {el?.reviewRating}
                  <Icon size={16} material iconName="star_rate" />
                </h5>
                {el?.reviewRating && <h5 className="desc">{capitalize(HOTEL_GUEST_SCORE[round(el?.reviewRating)])}</h5>}
              </div>
              <p className="comment">{el?.reviewText}</p>
              <div className="date-user-styles">
                <p>{moment(el?.creationDate).format('DD MMM YYYY')}</p>
                <div className="dot" />
                <p>{el?.userName}</p>
              </div>
              <hr className="horizontal-line" />
            </div>
          ))}
          {reviews?.length > 3 && (
            <Button small type="secondary" className="all-review-button" onClick={() => modalRef.current.open()}>
              {`${getText('readAllReviews')} (${reviews?.length})`}
            </Button>
          )}
        </div>
      ) : (
        <LoadingReviewCart />
      )}

      <Modal ref={modalRef} closeIcon css={modalContainer}>
        <h4>{getText('reviews')}</h4>
        <hr className="horizontal-line" />
        <div className="scrollable-container">
          {reviews?.map((el, ind) => (
            <div key={ind} css={reviewCart}>
              <div className="creation-user">
                <h5 className="review-rating">
                  {el?.reviewRating}
                  <Icon size={16} material iconName="star_rate" />
                </h5>
                {el?.reviewRating && <h5 className="desc">{capitalize(HOTEL_GUEST_SCORE[round(el?.reviewRating)])}</h5>}
              </div>
              <p className="comment">{el?.reviewText}</p>
              <div className="date-user-styles">
                <p>{moment(el?.creationDate).format('DD MMM YYYY')}</p>
                <div className="dot" />
                <p>{el?.userName}</p>
              </div>
              <hr className="horizontal-line" />
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

ReviewCart.propTypes = {
  reviews: PropTypes.array,
};

export default ReviewCart;
