import { useTranslations } from '@veraio/strank';
import { Spinner } from 'components';
import { mainContainer } from './styles';
// import image from 'assets/icons/icn-alert-error.svg';

const NotFound = () => {
  const { getText } = useTranslations();

  return (
    <div css={mainContainer}>
      <Spinner loading text={getText('loading')} />
      {/* <Image src={image} alt="404 page" width="600px" /> */}
    </div>
  );
};

export default NotFound;
