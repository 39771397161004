import { theme } from 'styles';

export const walletContainer = {
  marginTop: 32,
  padding: '64px 32px',

  '.calculated-prices-container': {
    marginBottom: 48,
    '.calculated-prices': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '.price': {
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 4,
        color: theme.gray800,
      },

      '.wallet': {
        fontSize: 14,
        color: theme.gray400,
      },

      '.plus': {
        backgroundColor: theme.gray100,
        padding: '8px 12px',
        fontWeight: 500,
        borderRadius: '50%',
        margin: '0 32px ',
      },
    },
  },

  '.title': {
    marginBottom: 16,
  },

  '.wallet-cart-container': {
    display: 'flex',
    flexDirection: 'row',

    '.wallet-cart': {
      marginRight: 16,

      '&:last-child': {
        marginRight: 0,

        '@media (max-width: 992px)': {
          marginBottom: 0,
        },
      },

      '@media (max-width: 992px)': {
        marginRight: 0,
        marginBottom: 16,
      },
    },

    '@media (max-width: 992px)': {
      flexDirection: 'column',
    },
  },

  '.button-life': {
    color: theme.blue,
    fontWeight: 600,
    padding: 0,

    '.button-text': {
      marginRight: 8,
    },
  },

  '@media (max-width: 767px)': {
    marginTop: 0,
    padding: 0,
    paddingLeft: 100,
  },
};
