import { useTranslations } from '@veraio/strank';
import { Button } from 'components';
import { ROUTES } from 'enums';
import { ReactComponent as CancelCalendarIcon } from 'assets/images/cancel-calendar.svg';
import { successCancelBookingContainer } from './styles';

const SuccessCancelBooking = ({ history }) => {
  const { getText } = useTranslations();

  return (
    <div css={successCancelBookingContainer}>
      <div className="cancel-content-container">
        <CancelCalendarIcon className="cancel-calendar" />
        <div className="cancel-info-container">
          <h4 className="title">{getText('RefundRequestHasBeenSent')}</h4>
          <p className="cancel-desc">{getText('forDetailedInformationAboutAllBookingsWithOngoingRefunds')}</p>

          <Button onClick={() => history.push(ROUTES.MyBookings)} className="button">
            {getText('viewBookings')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessCancelBooking;
