import { theme } from 'styles';

export const checkoutPriceContainer = {
  marginBottom: 40,

  '.title': {
    fontWeight: 500,
  },

  '.price-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '12px 0',
    color: theme.gray800,

    '.total-styles': {
      fontWeight: 600,
    },
  },

  '.horizontal-line': {
    backgroundColor: theme.gray200,
  },
};
