import { theme } from 'styles';

export const updateContainer = {
  backgroundColor: theme.white,
  padding: '24px 84px',

  '.title': {
    marginBottom: 12,
  },

  '.update-row': {
    display: 'flex',
    flexDirection: 'row',
  },

  '.update-filters-container': {
    '.search-bar': {
      '.suggestion': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 16,

        '.icon-container': {
          padding: '6px 8px',
          backgroundColor: theme.infoLight,
          borderRadius: 10,
          marginRight: 12,
        },
      },
      '& > div': {
        borderRadius: 10,
        border: `1px solid ${theme.gray150}`,
      },
    },

    '.date-range-picker': {
      '.picker-container': {
        borderRadius: '10px !important',
        border: `1px solid ${theme.gray150}`,
      },

      '.DateRangePicker': {
        '.DateRangePickerInput': {
          border: 'none !important',
          height: 48,

          '.DateInput_input': {
            padding: 0,
          },
        },
      },
    },

    '.edit-container': {
      borderRadius: '10px !important',
      border: `1px solid ${theme.gray150}`,

      '& > div': {
        borderRadius: 10,
      },
    },

    '.visitors-tooltip': {
      top: 60,
    },

    '.button': {
      borderRadius: 10,
    },
  },
};

export const updateBodyContainer = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  overflow: 'hidden auto',
  display: 'block',
};
