import { theme } from 'styles';

export const searchContainer = {
  '.search-bar': {
    '.suggestion': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 16,

      '.icon-container': {
        padding: '6px 8px',
        backgroundColor: theme.infoLight,
        borderRadius: 10,
        marginRight: 12,
      },
    },
  },
};
