import { theme } from 'styles';

export const modalContainer = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  top: 0,
  minWidth: 500,

  '& > div:first-of-type': {
    height: '100vh',
  },

  '.action-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.25)',
    padding: 16,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    backgroundColor: theme.white,
  },

  '.scrollable-container': {
    height: 'calc(100vh - 80px)',
    overflow: 'auto',
    zIndex: 1,
    marginBottom: 80,
  },

  '.scrollable-container::-webkit-scrollbar': {
    display: 'none',
  },

  '.horizontal-line': {
    backgroundColor: theme.gray200,
    margin: '32px 0',
  },

  '.title': {
    fontWeight: 500,
    fontSize: 18,
    marginBottom: 12,
  },

  '.subtitle': {
    fontSize: 13,
    color: theme.gray300,
    marginBottom: 16,
  },

  '.rate-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 12,

    '.rate-title': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '.count': {
        color: theme.gray200,
      },

      '.rate-description': {
        marginRight: 12,
      },
    },
  },

  '.rating-container': {
    display: 'flex',
    flexDirection: 'row',
    border: `1px solid ${theme.gray150}`,
    borderRadius: 25,
  },

  '.link-button': {
    color: theme.blue,
    padding: '12px 0',
    fontSize: 16,
    fontWeight: 500,
  },

  '.stars-container': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',

    '.badge': {
      marginRight: 12,
      whiteSpace: 'nowrap',
      marginBottom: 12,
    },
  },
};

export const badge = (isActive, padding) => [
  {
    border: `1px solid ${isActive ? theme.blue : theme.gray150}`,
    backgroundColor: isActive ? theme.infoLight : theme.white,
    borderRadius: 8,
    color: isActive ? theme.gray400 : theme.black,
    padding: padding,
    marginRight: 16,
    width: '100%',

    '.icon-star': {
      marginRight: 4,
    },

    '.star': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '.badge-container': {
      color: theme.gray800,
      fontSize: 16,

      '.icon': {
        color: theme.gray300,
        marginBottom: '24px !important',
      },
    },

    '&:last-child': {
      marginRight: 0,
    },
  },
];

export const guestRate = (firstActive, active) => {
  return [
    {
      backgroundColor: active ? theme.primary : 'transparent',
      borderTopLeftRadius: firstActive ? 25 : 0,
      borderBottomLeftRadius: firstActive ? 25 : 0,
      padding: '12px 24px',
      width: '100%',
      color: active ? theme.gray800 : theme.gray300,
      fontSize: 16,

      '&:last-child': {
        borderTopRightRadius: 25,
        borderBottomRightRadius: 25,
      },
    },
  ];
};
