import { Children, useEffect } from 'react';
import PropTypes from 'prop-types';
// REMOVE TAG HIDE FOR FIRST VERSION
// import moment from 'moment';
import { isArray, isFunction, pick } from 'lodash-es';
import { useUserStore } from 'stores';
import { useNavigationControls } from '../NavigationContext';
import MenuList from './MenuList';
import Content from './Content';
import { contentContainer } from './styles';

const SideNav = ({
  children: initChildren = [],
  routes = [],
  notFoundComponent,
  homeRoute,
  topNavigation,
  footer,
  closeBreakPoint = 992,
}) => {
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const { isAuthenticated, userInfo } = useUserStore((userState) => pick(userState, ['isAuthenticated', 'userInfo']));
  const { isAuthenticated } = useUserStore((userState) => pick(userState, ['isAuthenticated']));
  const children = Children.toArray(initChildren).filter(Boolean);
  const { changeAllRoutes } = useNavigationControls();
  const TopNav = topNavigation;
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const subscriptionLeft = moment(userInfo?.subscription?.startDate).add(userInfo?.subscription?.duration, 'months');
  // const isLeftSubscription = moment(subscriptionLeft).isBefore(moment());

  useEffect(() => {
    extractAllRoutes();
  }, [
    isAuthenticated,
    // REMOVE TAG HIDE FOR FIRST VERSION
    // isLeftSubscription,
  ]);

  const extractAllRoutes = () => {
    const allRoutesList = [...routes];

    const extractNestedRoutes = (elements) =>
      elements.forEach((el) => {
        const menu = { ...(el?.props ?? el) };
        if (isArray(menu?.subMenus)) {
          menu.redirectsTo = menu.subMenus[0]?.url;
          allRoutesList.push(menu);
          return extractNestedRoutes(menu.subMenus);
        }
        return allRoutesList.push(menu);
      });

    extractNestedRoutes(children);
    changeAllRoutes(allRoutesList ?? []);
  };

  const Footer = isFunction(footer) && footer;

  return (
    <>
      {TopNav && <TopNav />}
      <section css={contentContainer}>
        <MenuList sideNavChilds={children} closeBreakPoint={closeBreakPoint} />
        <Content notFoundComponent={notFoundComponent} homeRoute={homeRoute} />
      </section>
      {footer && <Footer />}
    </>
  );
};

SideNav.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  routes: PropTypes.array,
  notFoundComponent: PropTypes.any,
  homeRoute: PropTypes.string,
  topNavigation: PropTypes.any,
  footer: PropTypes.any,
  closeBreakPoint: PropTypes.number,
};

export default SideNav;
