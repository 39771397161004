import { Icon } from 'components';
import { featuresTypeCartContainer } from './styles';

const FeaturesTypeCart = ({ iconName, title, length, type, onClick, className }) => (
  <div
    css={featuresTypeCartContainer}
    {...(className && { className })}
    role="button"
    tabIndex={0}
    onClick={() => onClick(type)}>
    <div className="type-container">
      <Icon material iconName={iconName} size={24} className="type-icon" />
      <p className="type-title">{title}</p>
      <p className="type-length">{`(${length})`}</p>
    </div>
    <Icon material iconName="navigate_next" size={18} className="icon" />
  </div>
);

export default FeaturesTypeCart;
