import { useRef, useEffect } from 'react';
import moment from 'moment';
import { useTranslations } from '@veraio/strank';
import { Col, Row, AmenitiesModal, CheckoutPriceCart, CheckoutSummary, GuestsForm } from 'components';
import { checkoutReviewContainer } from './styles';

const Review = ({ roomDetails, hotelDetails, filters, formData, onCompleted, onSetGuests, onScrollToTop }) => {
  const { getText } = useTranslations();
  const amenitiesModalRef = useRef();

  const checkIn = moment(filters?.checkIn);
  const checkOut = moment(filters?.checkOut);
  const days = checkOut.diff(checkIn, 'days');

  useEffect(() => {
    onScrollToTop();
  }, []);

  return (
    <>
      <div css={checkoutReviewContainer} id="#header">
        <div className="title">
          <h3>{getText('review')}</h3>
        </div>
        <Row gap={32} className="summary-price-container">
          <Col sm={12} md={7} lg={7}>
            <h4 className="subtitle">{getText('summary')}</h4>
            <CheckoutSummary roomDetails={roomDetails} hotelDetails={hotelDetails} filters={filters} days={days} />
            <div className="refund-container">
              <h5>{getText('cancellationPolicy')}</h5>
              <p>{roomDetails?.cancellationDetails?.description}</p>
            </div>
            <h4 className="guests-title">{`${getText('guests')}`}</h4>
            <p className="guests-subtitle">{getText('guestCheckingHotelRoomPresent')}</p>
            <GuestsForm
              hotelId={hotelDetails?.hotelId}
              filters={filters}
              formData={formData}
              onCompleted={onCompleted}
              onScrollToTop={onScrollToTop}
              onSetGuests={onSetGuests}
            />
          </Col>
          <Col sm={12} md={5} lg={5}>
            <CheckoutPriceCart roomRateData={roomDetails} days={days} />
          </Col>
        </Row>
      </div>
      <AmenitiesModal ref={amenitiesModalRef} amenities={roomDetails?.amenities} />
    </>
  );
};

export default Review;
