import { CheckButton } from 'components';
import { ReactComponent as FiftyFiftyIcon } from 'assets/images/50-50.svg';
import { ReactComponent as OneHundredIcon } from 'assets/images/100.svg';
import { ReactComponent as TwentyFiveSeventyFiveIcon } from 'assets/images/75_25.svg';
import { paymentMethodCartContainer } from './styles';

const PaymentMethodCart = ({ paymentMethod, value, onClick, className }) => {
  const paymentMethodIconMap = (method) => {
    switch (true) {
      case method?.coinPercent === 50 || method?.cashPercent === 50 || method?.name?.includes('50'):
        return {
          icon: <FiftyFiftyIcon className="icon" />,
        };
      case method?.coinPercent === 100 || method?.cashPercent === 100 || method?.name?.includes('100'):
        return {
          icon: <OneHundredIcon className="icon" />,
        };
      case method?.coinPercent === 25 ||
        method?.coinPercent === 75 ||
        method?.cashPercent === 25 ||
        method?.cashPercent === 75 ||
        method?.method?.name('25') ||
        method?.method?.name('75'):
        return {
          icon: <TwentyFiveSeventyFiveIcon className="icon" />,
        };
      default:
        return <TwentyFiveSeventyFiveIcon className="icon" />;
    }
  };

  return (
    <div css={paymentMethodCartContainer} {...(className && { className })}>
      <div className="name-icon-container">
        {paymentMethodIconMap(paymentMethod)?.icon}
        <p>{paymentMethod?.name}</p>
      </div>
      <CheckButton radio value={value} onChange={(val) => onClick(val ? paymentMethod : null)} />
    </div>
  );
};

export default PaymentMethodCart;
