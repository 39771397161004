import { useRef, useState } from 'react';
import { capitalize } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Icon, Row, Col, Carousel, Button, AmenitiesModal, RoomPriceCart } from 'components';
import { bedTypesMap } from './config';
import { roomCartContainer } from './styles';

const RoomCart = ({ room, hotelDetails, filters }) => {
  const { getText } = useTranslations();
  const [expand, setExpand] = useState(false);
  const modalRef = useRef();

  return (
    <>
      <Row css={roomCartContainer(expand)}>
        <Col xs={12} sm={12} md={8} className="left-side">
          <Row gap={8} className="row">
            <Col xs={12} sm={12} md={expand ? 12 : 6} lg={expand ? 12 : 5}>
              <Carousel
                withShowAllImages
                images={room?.images?.length > 0 ? room?.images : hotelDetails?.hotelImages}
                width="100%"
                height={200}
              />
            </Col>
            <Col xs={12} sm={12} md={expand ? 12 : 6} lg={expand ? 12 : 7} className="description">
              <div>
                <h5 className="room-title">
                  {`${capitalize(getText(room?.roomType))}`} {room?.fareType !== '' && `(${room?.fareType})`}
                </h5>
                {room?.description !== '' && (
                  <p className="room-board-type">{capitalize(getText(room?.description))}</p>
                )}
                {room?.boardType !== '' && <p className="room-board-type">({capitalize(room?.boardType)})</p>}
                {(room?.squareFootage > 0 || room?.maxOccupancy !== '') && (
                  <div className="square-footage">
                    {room?.squareFootage > 0 && (
                      <>
                        <Icon material iconName="fullscreen" color="gray300" className="icon-room" />
                        <p>{getText('squareFootage', { count: room?.squareFootage })}</p>
                      </>
                    )}
                    {room?.maxOccupancy !== '' && (
                      <>
                        <div className="dot" />
                        <p>{getText('sleepsUpTo', { count: room?.maxOccupancy })}</p>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div>
                {room?.beds?.length > 0 && (
                  <div className="room-types">
                    {room?.beds?.map((el, ind) => (
                      <div key={ind} className="room-desc">
                        {`${el?.count} x `}
                        {bedTypesMap(getText)[el?.type.toUpperCase()]}
                        {ind !== room?.beds?.length - 1 && <div className="dot" />}
                      </div>
                    ))}
                  </div>
                )}
                {room?.amenities?.length > 0 && (
                  <div className="amenities">
                    {room?.amenities?.slice(0, 2)?.map((el, ind) => (
                      <div key={ind} className="amenities">
                        <Icon size={16} material iconName="check" className="icon-check" />
                        <p className="amenity-name">{el?.name}</p>
                      </div>
                    ))}

                    {room?.amenities?.length && (
                      <Button small type="link" onClick={() => modalRef.current?.open()} className="button">
                        <div className="action">
                          {getText('amenities')}
                          <Icon material iconName="keyboard_arrow_right" className="icon" />
                        </div>
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={12} md={4}>
          {!expand ? (
            <RoomPriceCart
              room={room}
              hotelDetails={hotelDetails}
              filters={filters}
              expand={expand}
              onExpand={(val) => setExpand(val)}
            />
          ) : (
            room?.rateData?.map((el, ind) => (
              <RoomPriceCart
                key={ind}
                room={room}
                hotelDetails={hotelDetails}
                filters={filters}
                expand={expand}
                onExpand={(val) => setExpand(val)}
              />
            ))
          )}
        </Col>
      </Row>
      <AmenitiesModal ref={modalRef} amenities={room?.amenities} />
    </>
  );
};

export default RoomCart;
