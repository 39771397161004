import { theme } from 'styles';

export const subscriptionContainer = {
  backgroundColor: theme.blueDarker,

  '.subscription-banner': {
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },

  '.subscription-container': {
    color: theme.white,
    padding: '64px 128px',
    justifyContent: 'stretch',

    '.subscription-subtitle': {
      marginBottom: 32,
    },

    '.column-cart': {
      display: 'flex',
    },
  },
};
