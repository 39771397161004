import { useEffect, useCallback, useState } from 'react';

export const useLeavePage = (history, isLeavePage, leavingModalRef, pathState) => {
  const [triggerExit, setTriggerExit] = useState({ onOk: false, path: '' });

  const handleGoToAnotherPage = useCallback(
    (location) => history.push({ pathname: location.pathname, state: { ...pathState } }),
    [history?.location?.pathname],
  );

  useEffect(() => {
    if (triggerExit.onOk) handleGoToAnotherPage(triggerExit.path);

    const unblock = history.block((location) => {
      if (isLeavePage) leavingModalRef.current?.open();
      if (!isLeavePage) return;

      setTriggerExit((obj) => ({ ...obj, path: { pathname: location.pathname, state: pathState } }));
      if (triggerExit.onOk) return true;

      return false;
    });

    return () => unblock();
  }, [handleGoToAnotherPage, history, triggerExit.onOk, triggerExit.path]);

  return {
    handleGoToAnotherPage,
    triggerExit,
    setTriggerExit,
  };
};
