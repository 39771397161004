import { commonStyles, theme } from 'styles';

export const visitorsContainer = {
  zIndex: 1,
  position: 'relative',

  '.container': {
    position: 'absolute',
    top: 50,
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    backgroundColor: theme.gray50,
    borderRadius: 6,
    color: theme.primary,
    width: 300,
    boxShadow: theme.boxShadow,

    '.items': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      '.item-info': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',

        '.title': {
          fontSize: 16,
          marginBottom: 4,
          color: theme.gray800,
        },

        '.subtitle': {
          fontSize: 14,
          color: theme.gray300,
        },
      },
    },

    '.error-message': {
      color: theme.errorDark,
      textDecoration: 'underline',
      textDecorationColor: theme.errorDark,
    },

    '.dropdown': {
      '.input-label': {
        marginTop: 4,
      },
    },

    '.button': {
      borderRadius: 10,
      fontWeight: 600,
      fontSize: 16,
    },
  },
};

export const visitors = (props) => [
  {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    width: '100%',
    backgroundColor: theme.gray50,
    height: 48,
    padding: '0 12px',

    '.container-visitors': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    '.icon': {
      color: theme.gray400,
      marginRight: 12,
    },

    '.information': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& > *': {
        marginRight: 8,

        '&:last-child': {
          marginRight: 0,
        },
      },

      '.dots': {
        width: 6,
        height: 6,
        backgroundColor: theme.gray150,
        borderRadius: '50%',
      },
    },
  },
  commonStyles(props),
];
