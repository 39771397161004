import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isFunction, omit } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import {
  BodyOverflow,
  Button,
  Col,
  DateRangePicker,
  Icon,
  Row,
  SearchBar,
  VisitorsInput,
  useUrlParams,
} from 'components';
import { getLocations } from 'services';
import { updateContainer, updateBodyContainer } from './styles';

const DISPLAY_FORMAT = 'D MMM YYYY';

const UpdateSearchFilters = (props) => {
  const { onClose, onUpdate, onApplyFilters } = props;
  const { getText } = useTranslations();
  const { clearQueryParams, queryParams } = useUrlParams();
  const { checkIn, checkOut, location, locationName, adults, children, rooms, childrenAges } = queryParams;
  const [params, setParams] = useState({});

  useEffect(() => {
    const handleKeyDown = (e) => (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) && onClose();
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleDelete = (options) => {
    options.inputProps.onDelete();
    clearQueryParams(['locationName', 'location']);
  };

  const handleSearchChange = (value) => {
    setParams((prev) => ({
      ...prev,
      location: value,
      locationName: value.display_name,
      latitude: value.lat,
      longitude: value.lon,
    }));
  };

  const handleDateChange = (newParams) => {
    const checkInData = moment(newParams.startDate).format('MM-D-YYYY');
    const checkOutData = moment(newParams.endDate).format('MM-D-YYYY');
    const isNewDates = checkInData !== checkIn || checkOutData !== checkOut;

    isNewDates && setParams((prev) => ({ ...prev, checkIn: checkInData, checkOut: checkOutData }));
  };

  const handleVisitorsChange = (value) => {
    const isChanged =
      value.adults !== queryParams.adults ||
      value.children !== queryParams.children ||
      value.rooms !== queryParams.rooms ||
      value.childrenAges !== queryParams.childrenAges;

    isChanged &&
      setParams((prev) => ({
        ...prev,
        adults: value.adults,
        children: value.children,
        rooms: value.rooms,
        childrenAges: value.childrenAges,
      }));
  };

  const handleChange = () => {
    isFunction(onApplyFilters) && onApplyFilters({ ...queryParams, ...params });
    onUpdate(false);
  };

  return (
    <BodyOverflow fixed fullScreen css={updateBodyContainer} zIndex={1000} onClick={onClose}>
      <div css={updateContainer} role="presentation" onClick={(e) => e.stopPropagation()}>
        <h4 className="title">{getText('updateSearch')}</h4>
        <Row gap={12} verticalGap={8} className="update-filters-container">
          <Col md={12} lg={4}>
            <SearchBar
              className="search-bar"
              placeholder="Where are you going?"
              displayKey={'display_name'}
              value={locationName ?? location?.display_name ?? params?.location?.display_name}
              getOptions={(input) => getLocations({ name: input })}
              renderSuggestion={(val) => (
                <div
                  className="suggestion"
                  {...val.itemProps}
                  role={val.itemProps.role}
                  onClick={() => {
                    handleSearchChange(val.item);
                    val.suggestionItemProps.onClick(val.item);
                  }}>
                  <div className="icon-container">
                    <Icon material iconName="location_on" size={16} color="black" />
                  </div>
                  {val.item.display_name}
                </div>
              )}
              renderInput={(val) => (
                <div {...val.containerProps}>
                  <label htmlFor={val.inputProps.id}>
                    <Icon {...val.iconProps} />
                  </label>
                  <input {...omit(val.inputProps, 'onFocus', 'onDelete')} />
                  <label htmlFor={val.inputProps.id}>
                    <Icon
                      material
                      size={20}
                      iconName="close"
                      css={val.iconProps.css}
                      onClick={() => handleDelete(val)}
                    />
                  </label>
                </div>
              )}
              onInputChange={handleSearchChange}
            />
          </Col>
          <Col sm={12} md={6} lg={3}>
            <DateRangePicker
              showClear={false}
              noFuture={false}
              withCalendarIcon
              startDate={checkIn}
              endDate={checkOut}
              onChange={handleDateChange}
              minDate={moment()}
              displayFormat={DISPLAY_FORMAT}
              className="date-range-picker"
            />
          </Col>
          <Col sm={12} md={6} lg={4} className="visitors-container">
            <VisitorsInput
              values={{
                adults: params?.adults ? params?.adults : adults,
                children: params?.children ? params?.children : children,
                rooms: params?.rooms ? params?.rooms : rooms,
                childrenAges: params?.childrenAges ? params.childrenAges : childrenAges,
              }}
              onChange={handleVisitorsChange}
              tooltipClassName="tooltip"
              className="edit-container"
            />
          </Col>
          <Col md={12} lg={1}>
            <Button onClick={handleChange} className="button">
              {getText('search')}
            </Button>
          </Col>
        </Row>
      </div>
    </BodyOverflow>
  );
};

UpdateSearchFilters.propTypes = {
  onClose: PropTypes.func,
  onApplyFilters: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default UpdateSearchFilters;
