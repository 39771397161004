import { theme } from 'styles';

export const bookingDetailsContainer = {
  position: 'relative',
  width: '100vw',

  '.gallery-background-container': {
    height: 524,

    '.background': {
      background: `linear-gradient(360deg, ${theme.infoLighter} 0%,${theme.secondaryLightDarker} 100%)`,
      height: 360,
      width: '100%',
      padding: '0 !important',
    },

    '.galleries-container': {
      padding: '0 84px',
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      width: '100%',

      '.hotel-gallery': {
        width: '100%',

        '.image-gallery-slides': {
          maxHeight: 500,
          width: '100%',
          objectFit: 'contain',
        },

        '.first-image': {
          height: 500,
          borderRadius: '10px 0 0 10px',
        },

        '.second-image': {
          height: 250,
          minHeight: 250,
          borderRadius: '0 10px 0 0',
        },

        '.third-image': {
          height: 246,
        },

        '.last-image': {
          height: 246,
          borderRadius: '0 0 10px 0',
        },
      },

      '.carousel-styles': {
        '.images': {
          '& > img': {
            width: 'calc(100vw - 168px)',
            borderRadius: 10,
            objectFit: 'fill',
            height: 500,
          },
        },
      },
    },
  },

  '.container': {
    padding: '0 84px',

    '.section-title': {
      marginBottom: 16,
      fontWeight: 500,
    },

    '.booking-badge-id-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 8,

      '.booking-id': {
        fontWeight: 500,
        fontSize: 14,
        color: theme.gray400,
        marginRight: 8,
      },

      '.booking-badge': {
        borderRadius: 20,
        fontWeight: 500,
      },
    },

    '.name-location-actions': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 24,

      '.title': {
        marginBottom: 4,
      },

      '.location': {
        color: theme.gray500,
        marginBottom: 8,
        fontSize: 14,
      },

      '.action-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '.booking-details-button': {
          color: theme.blue,
          fontWeight: 600,
          padding: 0,
          marginRight: 8,

          '&:hover': {
            borderColor: 'none',
          },

          '&:active': { outline: 'none' },
        },

        '.danger-button': {
          backgroundColor: theme.errorLight,
          color: theme.errorDark,
          borderColor: theme.errorLight,
          fontWeight: 600,
        },

        '@media (max-width: 767px)': {
          flexWrap: 'wrap',
        },
      },

      '@media (max-width: 576px)': {
        flexWrap: 'wrap',
      },
    },

    '.horizontal-line': {
      backgroundColor: theme.gray200,
      margin: '24px 0',
    },

    '.booking-details': {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 32,

      '.transaction-details': {
        borderRadius: 10,
        border: `1px solid ${theme.gray150}`,
        boxShadow: theme.boxShadowDark,
      },
    },
  },
};

export const maps = {
  marginBottom: 32,
};
