import { theme } from 'styles';

export const myBookingsContainer = {
  padding: '64px 128px',

  '.title': {
    marginBottom: 24,
  },

  '.tabs-container': {
    '.tabs-list-container': {
      borderBottom: `1px solid ${theme.gray150}`,
    },
  },
};
