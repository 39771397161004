import { theme } from 'styles';

export const dateRangeContainer = {
  position: 'relative',
  zIndex: 1,

  '.min-dot': {
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: `1px solid ${theme.blue}`,
    position: 'absolute',
    top: -10,
    right: 0,
    zIndex: 100,
    cursor: 'pointer',
    touchAction: 'none',
  },

  '.max-dot': {
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: `1px solid ${theme.blue}`,
    position: 'absolute',
    top: -10,
    left: 0,
    zIndex: 100,
    cursor: 'pointer',
    touchAction: 'none',
  },

  '.input': {
    pointerEvents: 'none',
    height: 0,
    width: '100%',
    outline: 'none',

    position: 'relative',
    userSelect: 'none',
    border: `1px solid ${theme.gray400}`,
  },

  '.slider__left-value': {
    left: 0,
    color: '#dee2e6',
    fontSize: 12,
    position: 'absolute',
  },

  '.slider__right-value': {
    right: 0,
    color: '#dee2e6',
    fontSize: 12,
    position: 'absolute',
  },
};
