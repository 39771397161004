import { theme } from 'styles';

export const paymentMethodCartContainer = {
  border: `1px solid ${theme.gray150}`,
  padding: '32px 24px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  '.name-icon-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '.icon': {
      width: 24,
      height: 24,
      marginRight: 12,
    },
  },
};
