import { theme } from 'styles';

export const currencyLanguageContainer = (isEditedMode) => [
  {
    borderRadius: 10,
    border: `0.5px solid ${theme.gray150}`,
    boxShadow: theme.boxShadowDark,
    backgroundColor: theme.white,
    padding: '20px 24px',
    display: !isEditedMode && 'flex',
    flexDirection: !isEditedMode && 'row',
    alignItems: !isEditedMode && 'center',
    justifyContent: !isEditedMode && 'space-between',

    '.title-icon': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: isEditedMode && 32,

      '.icon': {
        marginRight: 8,
      },

      '.title-styles': {
        color: theme.gray800,
        fontSize: 16,
        fontWeight: 400,
      },
    },

    '.dropdown': {
      marginBottom: 16,
      '.select-dropdown': {
        borderRadius: 8,
      },
    },

    '.buttons-container': {
      '.save-button': {
        marginRight: 16,
        color: theme.gray800,
        fontWeight: 600,
      },

      '.cancel-button': {
        color: theme.gray800,
        fontWeight: 600,
      },
    },

    '.button-language': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '.language-container': {
        '.language-code-symbol': {
          fontSize: 16,
          fontWeight: 500,
          color: theme.gray400,
        },

        '.currency': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },

        '.dot': {
          width: 5,
          height: 5,
          borderRadius: '50%',
          backgroundColor: theme.gray150,
          margin: '0 8px',
        },
      },

      '.button': {
        color: theme.blue,
        fontWeight: 600,

        '&:hover': {
          borderColor: 'none',
          backgroundColor: 'none',
        },

        '&:active': { outline: 'none' },
      },
    },
  },
];
