import { div } from 'exact-math';
import { capitalize } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { PriceLabel } from 'components';
import { DEFAULT_CURRENCY } from 'enums';
import { checkoutPriceContainer } from './styles';

const CheckoutPriceCart = ({ roomRateData, days }) => {
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  const pricePerNight = div(roomRateData?.priceDetails?.price ?? 0, days);

  const convertedToLocalCurrencyPrice = (price) =>
    price && (
      <PriceLabel
        fiat={Number(price)}
        fiatOriginCurrency={DEFAULT_CURRENCY.code}
        fiatDesiredCurrency={selectedCurrency?.code}
      />
    );

  return (
    <div css={checkoutPriceContainer}>
      <h4>{getText('price')}</h4>

      <div className="price-container">
        <p>
          {convertedToLocalCurrencyPrice(pricePerNight)} <span>{` x ${days} ${getText('nights')}`}</span>
        </p>
        <p>{convertedToLocalCurrencyPrice(roomRateData?.priceDetails?.price)}</p>
      </div>
      <div className="price-container">
        <p>{getText('cryptoWallet')}</p>
        <PriceLabel crypto={roomRateData?.priceDetails?.cryptoPrice} ignoreEmpty />
      </div>

      <div className="price-container">
        <p>{capitalize(getText('dailyPropertyFee'))}</p>
        <p>{convertedToLocalCurrencyPrice(roomRateData?.priceDetails?.displayTaxes)}</p>
      </div>

      <hr className="horizontal-line" />

      <div className="price-container">
        <p className="total-styles">{getText('totalCost')}</p>
        <p className="total-styles">
          <PriceLabel
            fiat={Number(roomRateData?.priceDetails?.price)}
            crypto={Number(roomRateData?.priceDetails?.cryptoPrice)}
            fiatOriginCurrency={DEFAULT_CURRENCY.code}
            fiatDesiredCurrency={selectedCurrency?.code}
          />
        </p>
      </div>
    </div>
  );
};

export default CheckoutPriceCart;
