import { theme } from 'styles';

export const searchContainer = {
  position: 'relative',
  top: -20,
  padding: '0 16px',

  '.listing-filters-container': {
    '.search-bar': {
      '.suggestion': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 16,

        '.icon-container': {
          padding: '6px 8px',
          backgroundColor: theme.infoLight,
          borderRadius: 10,
          marginRight: 12,
        },
      },
      '& > div': {
        borderRadius: '25px 0px 0px 25px !important',
        borderRight: `1px solid ${theme.gray150}`,
        marginRight: 12,
      },
    },

    '.date-range-picker': {
      '.picker-container': {
        border: 'none',
        borderRight: `1px solid ${theme.gray150}`,
      },

      '.DateRangePicker': {
        '.DateRangePickerInput': {
          borderRadius: '0 !important',
          border: 'none !important',
          height: 48,

          '.DateInput_input': {
            padding: 0,
          },
        },
      },
    },

    '.visitors-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.gray50,
      height: 48,
      borderRadius: '0px 25px 25px 0px',
      marginTop: 8,

      '.icon': {
        marginRight: 8,
        color: theme.gray400,
      },
    },
  },

  '.loading-filters-container': {
    marginBottom: 12,
    borderRadius: 25,
  },

  '.price-row': {
    backgroundColor: theme.white,

    '.hotel-title': {
      marginBottom: 8,
    },

    '.icon': {
      marginRight: 8,
    },

    '.dropdown': {
      backgroundColor: theme.white,
      marginTop: 0,
    },

    '.button': {
      fontWeight: 600,
      fontSize: 16,
      padding: 12,
      height: 48,
      width: '100%',
    },
  },
};

export const pricesContainer = {
  display: 'flex',
  flexDirection: 'row',
  color: theme.infoDark,

  '.subtitle': {
    color: theme.gray300,
    fontWeight: 500,
  },
};

export const tooltipContent = {
  '& .tooltip-content': {
    padding: '0.6em 0.7em',
    backgroundColor: theme.white,
    borderRadius: 10,
    width: 400,
  },
};
