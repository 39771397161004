import { theme } from 'styles';

export const roomCartContainer = (isExpanded) => ({
  backgroundColor: theme.gray50,
  marginBottom: 32,

  '.left-side': {
    padding: 16,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '10px 0px 0px 10px',

    '.row': {
      width: '100%',
      display: isExpanded && 'block',
    },

    '.description': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: !isExpanded && 'space-between',

      '.room-title': {
        marginBottom: 4,
      },

      '.room-board-type': {
        marginBottom: 8,
        color: theme.gray700,
      },

      '.square-footage': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.gray300,
        fontWeight: 500,
      },

      '.dot': {
        width: 5,
        height: 5,
        backgroundColor: theme.gray150,
        borderRadius: 50,
        margin: '0 8px',
      },

      '.room-types': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.gray400,
        fontWeight: 500,

        '.room-desc': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
      },

      '.icon-room': {
        marginRight: 8,
      },

      '.amenities': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.successDark,

        '.icon-check': {
          marginRight: 4,
        },

        '.amenity-name': {
          fontSize: 12,
          fontWeight: 500,
          marginRight: 8,
        },

        '@media (max-width: 576px)': {
          flexWrap: 'wrap',
        },
      },

      '.button': {
        padding: 0,

        '&:active': { outline: 'none' },

        '.action': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          color: theme.blue,
          fontWeight: 600,
        },
      },
    },
  },
});

export const bedsContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};
