import { useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Modal, Icon } from 'components';
import { amenitiesModalContainer } from './styles';

const AmenitiesModal = forwardRef((props, ref) => {
  const { amenities } = props;
  const { getText } = useTranslations();
  const modalRef = useRef();

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current?.open(),
    close: () => modalRef.current?.close(),
  }));

  return (
    <Modal ref={modalRef} closeIcon css={amenitiesModalContainer}>
      <h4>{getText('amenities')}</h4>
      <hr className="horizontal-line" />

      <div className="scrollable-container">
        {amenities?.map((el, ind) => (
          <div key={ind}>
            <div className="amenities-container">
              <Icon material iconName="check" className="icon-check" />
              <p>{el?.name}</p>
            </div>
            <hr className="horizontal-line" />
          </div>
        ))}
      </div>
    </Modal>
  );
});

AmenitiesModal.propTypes = {
  amenities: PropTypes.array,
};

export default AmenitiesModal;
