import { theme } from 'styles';

export const paymentCartContainer = (hasError) => ({
  marginBottom: 16,
  border: hasError?.paymentMethod && `1px solid ${theme.errorDark}`,
  borderRadius: hasError?.paymentMethod ? 10 : '0 0 10px 10px',

  '.payment-cart': {
    '&:first-of-type': {
      borderRadius: '10px 10px 0 0',
    },

    '&:last-child': {
      borderRadius: '0 0 10px 10px',
    },
  },
});
