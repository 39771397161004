import { Col, Row, Skeleton } from 'components';
import { roomCartContainer } from './styles';

const LoadingRoomCard = () =>
  Array(10)
    .fill()
    .map((_, i) => (
      <Row key={i} css={roomCartContainer}>
        <Col xs={12} sm={12} md={8} className="left-side">
          <Row gap={8} className="row">
            <Col xs={12} sm={12} md={6} lg={5}>
              <Skeleton width="100%" height={200} />
            </Col>
            <Col xs={12} sm={12} md={6} lg={7} className="description">
              <div>
                <Skeleton width="100%" height={17} className="room-title" />
                <Skeleton width="100%" height={17} className="square-footage" />
              </div>
              <div>
                <Skeleton width="100%" height={22} className="room-title" />
                <Skeleton width="100%" height={22} className="amenities" />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={12} md={4} className="right-side">
          <Skeleton width="100%" height={22} />
          <Skeleton width="100%" height={46} />
        </Col>
      </Row>
    ));

export default LoadingRoomCard;
