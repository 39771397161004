import { theme } from 'styles';

export const successCancelBookingContainer = {
  height: 'calc(100vh - 90px)',
  backgroundColor: theme.secondary,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',

  '.cancel-content-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '64px 128px',

    '.cancel-calendar': {
      width: 200,
      minWidth: 200,
      height: 200,
      marginRight: 20,
    },

    '.cancel-info-container': {
      maxWidth: 500,

      '.title': {
        marginBottom: 12,
      },

      '.cancel-desc': {
        color: theme.gray400,
        marginBottom: 32,
      },

      '.button': {
        fontWeight: 600,
      },
    },

    '@media (max-width: 767px)': {
      flexWrap: 'wrap',
    },

    '@media (max-width: 576px)': {
      padding: '64px 0px 64px 100px ',
    },
  },
};
