import { theme } from 'styles';

const MENU_SMALL_WIDTH = 66;

export const sideNavContainer = {
  position: 'absolute',
  bottom: '50%',
  left: 0,
  zIndex: 100,
  transform: 'translateY(50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: MENU_SMALL_WIDTH,
  flexShrink: 0,
  transition: 'width .3s ease-in-out',
};

export const menuListContainer = [
  {
    overflow: 'hidden auto',
    height: 'auto',
    padding: '1.2em 0 1.2em 0',
    position: 'relative',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    transition: 'width .3s ease-in-out',
    zIndex: 1000,
    width: '100%',
    borderRadius: '0px 10px 10px 0px',
    backgroundColor: theme.white,
  },
];

export const subMenuList = {
  backgroundColor: theme.gray800,
  color: theme.gray700,
  borderRadius: 4,
  minWidth: 120,
};

export const subMenusTreeContainer = {
  padding: '0 1rem 0 3.2rem',
};

export const contentContainer = {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  overflow: 'auto hidden',
  height: '100%',
};

export const sideNavContent = {
  flex: 1,
  overflow: 'auto',
  height: 'calc(100vh - 90px)',
};

export const tooltipContainer = {
  display: 'block',
};

export const tooltipContent = {
  '& .tooltip-content': {
    padding: '0.6em 1.4em',
    backgroundColor: theme.gray800,
    boxShadow: 'none',
    marginLeft: 32,
    borderRadius: 40,
  },
};

export const menuItem = {
  color: theme.gray300,
  backgroundColor: 'transparent',
  padding: '1em 0.9em',
  margin: '0 0.6rem',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexFlow: 'row nowrap',
  cursor: 'pointer',
  borderRadius: 10,
  marginBottom: '1rem',
  lineHeight: 1,
  whiteSpace: 'nowrap',

  '&:hover': {
    color: theme.gray400,
    backgroundColor: theme.gray100,
  },

  '&:focus': {
    outline: 'none',
  },

  '& i': {
    marginRight: '0.5em',
    color: 'inherit',
  },

  '&.active': {
    color: theme.gray700,
    backgroundColor: theme.secondary,
  },

  //  REMOVE TAG HIDE FOR FIRST VERSION
  // '&:last-child': {
  //   borderTop: `1px solid ${theme.gray150}`,
  //   borderRadius: '0px 0px 10px 10px',
  // },
};

export const menuSubItem = {
  color: theme.whiteDark,
  backgroundColor: 'transparent',
  padding: '1em 0.8em',
  cursor: 'pointer',
  borderRadius: 4,
  display: 'block',
  whiteSpace: 'nowrap',

  '&:hover': {
    color: theme.white,
    backgroundColor: theme.gray700,
  },

  '&.active': {
    color: theme.white,
    fontWeight: 500,
  },
};

export const menuItemText = {
  display: 'block',
};

export const menuItemRightIcon = {
  color: theme.white,
  fontSize: 11,
};
