import { getToken } from '@oneecosystem/authenticate';
import { getText } from '@veraio/strank';
import { useStore, createStore, showApiError, showError } from 'components';
import { USER_STATUS } from 'enums';
import { getOneLifeProfile, getOneVoyageProfile, createUser } from 'services';

const userStore = createStore({
  isAuthenticated: !!getToken(),
  userInfo: null,
  accounts: [],
});

const createNewUser = async (lifeUser) => {
  const [createInternalUser, createInternalUserErr] = await createUser();
  if (createInternalUserErr) return showApiError(createInternalUserErr);

  userStore.setState({
    userInfo: { ...lifeUser, ...createInternalUser },
    accounts: lifeUser?.accounts?.map((el) => ({ ...el, displayName: `#${el.id} ${el.nickName}` })),
    isAuthenticated: true,
  });
};

export const initializeUser = async () => {
  const [lifeUser, lifeErr] = await getOneLifeProfile();
  if (lifeErr) return showApiError(lifeErr);
  if (lifeUser?.status.toLowerCase() !== USER_STATUS.Active.toLowerCase())
    return showError(getText('userProfileIsNotActive'));

  const [voyageProfile, voyageProfileError] = await getOneVoyageProfile();

  if (voyageProfileError) {
    voyageProfileError.response.status === 400 &&
    voyageProfileError.response.data.messages?.find((el) => el === 'The user does not exist!')
      ? createNewUser(lifeUser)
      : showApiError(voyageProfileError);
  }

  userStore.setState({
    userInfo: { ...lifeUser, ...voyageProfile },
    accounts: lifeUser?.accounts?.map((el) => ({ ...el, displayName: `#${el.id} ${el.nickName}` })),
    isAuthenticated: true,
  });
};

export const useUserStore = (...args) => useStore(userStore, ...args);
