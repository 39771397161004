import { theme } from 'styles';

export const guestFromContainer = {
  borderRadius: 10,
  border: `1px solid ${theme.gray150}`,
  padding: 24,

  '.room-title': {
    marginBottom: 16,
  },

  '.user-info': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.gray100,
    padding: 10,
    marginBottom: 16,
    borderRadius: 10,
    border: `1px solid ${theme.gray150}`,
    margin: 12,
    marginRight: 6,

    '.avatar-image': {
      width: 32,
      height: 32,
      marginRight: 8,
    },
  },

  '.guest-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',

    '& *': {
      margin: '2px 12px 2px 6px',
    },
  },

  '.action-button': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};
