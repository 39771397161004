import { theme } from 'styles';

export const leaveModalContainer = {
  '.title': {
    marginBottom: 16,
  },

  '.subtitle': {
    color: theme.gray700,
    marginBottom: 48,
  },

  '.action-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};
