import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { Button, ColorBatch, PriceLabel, showApiError } from 'components';
import {
  DEFAULT_CURRENCY,
  ROUTES,
  TRANSACTION_STATUS_COLOR,
  TRANSACTION_STATUS_TYPE,
  TRANSACTION_SUB_KINDS,
} from 'enums';
import { getMyTransactions } from 'services';
import { ReactComponent as EmptyIcon } from 'assets/images/not-have.svg';
import { walletTransactionContainer } from './styles';

const WalletTransactionCart = () => {
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    fetchAllTransactions();
  }, []);

  const fetchAllTransactions = async () => {
    const [res, err] = await getMyTransactions({ pageIndex: 1, pageSize: 3 });
    if (err) return showApiError(err);
    setTransactions(res.results);
  };

  const convertedToLocalCurrencyPrice = (price) =>
    price && (
      <PriceLabel
        fiat={Number(price)}
        fiatOriginCurrency={DEFAULT_CURRENCY.code}
        fiatDesiredCurrency={selectedCurrency?.code}
      />
    );

  return (
    <div css={walletTransactionContainer}>
      {transactions?.length ? (
        <div className="transactions-container">
          <div className="titles">
            <h5>{getText('latestTransactions')}</h5>
            <Button type="link" linkTo={ROUTES.Transactions} className="action-button">
              {getText('viewAll')}
            </Button>
          </div>
          <div className="transactions-container">
            {transactions?.map((el, ind) => (
              <div key={ind} className="transactions">
                <div className="date-kind">
                  <h5 className="date">{moment(el.createdDate).format('DD MMM')}</h5>
                  <p>{el.reason}</p>
                </div>
                <div className="status-price">
                  <ColorBatch type={TRANSACTION_STATUS_COLOR[el?.status]?.color} className="color-batch">
                    {getText(TRANSACTION_STATUS_TYPE[el?.status])}
                  </ColorBatch>
                  <p>
                    {el.transactionSubKind === TRANSACTION_SUB_KINDS.OTHER ? (
                      el.amount
                    ) : el.transactionSubKind === TRANSACTION_SUB_KINDS.BOOKING_PAYMENT_WITH_CRYPTO ? (
                      <PriceLabel crypto={Number(el.amount)} ignoreEmpty />
                    ) : (
                      convertedToLocalCurrencyPrice(el.amount)
                    )}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="not-have-transactions">
          <EmptyIcon className="empty-icon" />
          <h4 className="title">{getText('thereAreNoTransactions')}</h4>
        </div>
      )}
    </div>
  );
};

export default WalletTransactionCart;
