import { theme } from 'styles';

export const galleryContainer = {
  '.image-gallery-slides': {
    maxHeight: 360,
    width: '100%',
    objectFit: 'contain',
  },

  '.first-image': {
    height: 364,
    borderRadius: '10px 0 0 10px',
  },

  '.second-image': {
    height: 180,
    borderRadius: '0 10px 0 0',
  },

  '.last-image-container': {
    position: 'relative',

    '.last-image': {
      height: 180,
      borderRadius: '0 0 10px 0',
    },

    '.button': {
      border: 'none',
      outline: 'none',

      '.action': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        position: 'absolute',
        top: 4,
        right: 4,
        left: 4,
        bottom: 50,
        borderRadius: '0 0 10px 0',

        '.label': {
          marginRight: 4,
          color: theme.white,
          fontWeight: 500,
        },
      },
    },
  },

  '.gallery-modal-container': {
    maxWidth: '100vw',
    maxHeight: 'calc(100vh - 300px)',
  },
};
