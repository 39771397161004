import { theme } from 'styles';

export const privacyPolicyContainer = {
  padding: '32px 128px',

  '.title': {
    marginBottom: 24,
  },

  '.text-container': {
    color: theme.gray400,
  },
};
