import { useEffect } from 'react';
import { useTranslations } from '@veraio/strank';
import { Row, Col, BackButton } from 'components';
import { termsAndConditionsContainer } from './styles';

const TermsAndConditions = () => {
  const { getText } = useTranslations();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div css={termsAndConditionsContainer}>
      <Row>
        <Col xs={12} xl={12}>
          <BackButton />
        </Col>
        <Col xs={12} xl={12}>
          <h3 className="title"> {getText('termsAndConditions')}</h3>
        </Col>
      </Row>
      <div className="text-container">
        <p>{getText('termsAndConditionsText')}</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
