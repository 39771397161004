import { useRef, forwardRef, useImperativeHandle, useState } from 'react';
import { isNil } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, CheckButton, Modal, showApiError, showError } from 'components';
import { ROUTES } from 'enums';
import { cancelBookingHotelByIds } from 'services';
import { CANCELLATION_REASONS } from './config';
import { cancelBookingModalContainer } from './styles';

const CancelBookingModal = forwardRef((props, ref) => {
  const { bookingId, confirmationNumber } = props;
  const { getText } = useTranslations();
  const [selectedReason, setSelectedReason] = useState(null);
  const modalRef = useRef();
  const history = useHistory();

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current?.open(),
    close: () => modalRef.current?.close(),
  }));

  const handleCancelBooking = async () => {
    if (isNil(selectedReason)) return showError('pleaseSelectReason');

    const cancelBody = {
      supplierConfirmationNum: confirmationNumber,
      referenceNum: bookingId,
      reason: selectedReason,
    };

    const [, err] = await cancelBookingHotelByIds(cancelBody);

    if (err) return showApiError(err);
    history.push(ROUTES.SuccessCancelBooking);
    modalRef.current?.close();
  };

  return (
    <Modal ref={modalRef} closeIcon css={cancelBookingModalContainer}>
      <h4 className="title">{getText('areYouSureCancelYourBooking')}</h4>

      <div className="reason-container">
        {CANCELLATION_REASONS?.map((el, ind) => (
          <div key={ind} className="reasons">
            <CheckButton
              radio
              value={el?.value === selectedReason}
              onChange={(val) => setSelectedReason(val ? el?.reason : null)}
            />
            <p>{getText(el?.label)}</p>
          </div>
        ))}
      </div>

      <div className="action-container">
        <Button className="button" type="secondary" onClick={() => modalRef.current?.close()}>
          {getText('no')}
        </Button>
        <Button className="button" loading disabled={isNil(selectedReason)} onClick={handleCancelBooking}>
          {getText('yes')}
        </Button>
      </div>
    </Modal>
  );
});

CancelBookingModal.propTypes = {
  bookingId: PropTypes.string,
  confirmationNumber: PropTypes.string,
};

export default CancelBookingModal;
