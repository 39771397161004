/* eslint-disable jsx-a11y/iframe-has-title */
import { useState } from 'react';
import { capitalize, isEmpty, isNil, round } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import {
  Button,
  Col,
  Icon,
  Row,
  Skeleton,
  AmenitiesSlider,
  AmenityCart,
  ReviewCart,
  CheckInOutPetsCart,
  PolicyCart,
} from 'components';
import { HOTEL_GUEST_SCORE } from 'enums';
import { validDate } from 'utils';
import { overviewContainer, maps } from './styles';

const Overview = ({ hotelDetails }) => {
  const { getText } = useTranslations();
  const [showReadMore, setShowReadMore] = useState(true);
  const description = showReadMore
    ? `${hotelDetails?.hotelDescription?.slice(0, 310)}...`
    : hotelDetails?.hotelDescription;

  return (
    <div css={overviewContainer(!showReadMore)}>
      <Row className="overview-desc-deal">
        <Col xs={12} md={6} lg={6} className="desc-container">
          <h3 className="titles">{getText('about')}</h3>
          {isNil(hotelDetails?.hotelDescription) ? (
            <Skeleton width="auto" height={210} className="description" />
          ) : (
            <>
              {
                <p
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              }
              <Button small type="link" className="action-button" onClick={() => setShowReadMore(!showReadMore)}>
                {showReadMore ? getText('readAll') : getText('showLess')}
                <Icon material iconName={showReadMore ? 'expand_more' : 'expand_less'} className="icon" />
              </Button>
            </>
          )}
        </Col>
        {((validDate(hotelDetails?.checkInTime) && validDate(hotelDetails?.checkOutTime)) ||
          !isNil(hotelDetails?.petFriendlyData?.policy)) && (
          <Col xs={12} md={6} lg={6}>
            <CheckInOutPetsCart hotelDetails={hotelDetails} />
          </Col>
        )}
      </Row>
      <h3 className="titles">{getText('amenities')}</h3>
      {isNil(hotelDetails?.highlightedAmenities) ? (
        <div className="amenity-container">
          {Array(6)
            .fill()
            .map((_, i) => (
              <Skeleton key={i} width={170} height={85} className="margin" />
            ))}
        </div>
      ) : (
        <AmenitiesSlider
          onlyShow
          withIcon
          hotelAmenities={hotelDetails?.highlightedAmenities}
          batchClassName="amenity"
        />
      )}
      <h3 className="title-amenity">{getText('allAmenities')}</h3>
      <AmenityCart amenities={hotelDetails?.hotelAmenities} />
      <Row gap={16} className="reviews">
        {isEmpty(hotelDetails) && (
          <Col xs={12} md={6} lg={6} className="review-info">
            <h3 className="title">{getText('whatPeopleSay')}</h3>
            <div className="review-container">
              <Icon material iconName="verified" color="successDark" className="verified-icon" />
              <p>{getText('allReviewsAreFromVerifiedGuests')}</p>
            </div>
            <div className="review-rating-info">
              <Skeleton width={35} height={67} className="rating-number" />
              <div>
                <Skeleton width={80} height={16} className="score-desc" />
                <Skeleton width={160} height={17} />
              </div>
            </div>
          </Col>
        )}
        {!isNil(hotelDetails?.hotelScore?.guestScore) && !isNil(hotelDetails?.hotelScore?.guestScoreCount) && (
          <Col xs={12} md={6} lg={6} className="review-info">
            <h3 className="title">{getText('whatPeopleSay')}</h3>
            <div className="review-container">
              <Icon material iconName="verified" color="successDark" className="verified-icon" />
              <p>{getText('allReviewsAreFromVerifiedGuests')}</p>
            </div>
            <div className="review-rating-info">
              <p className="rating-number">{hotelDetails?.hotelScore?.guestScore}</p>
              <div>
                <h5 className="score-desc">
                  {capitalize(HOTEL_GUEST_SCORE[round(hotelDetails?.hotelScore?.guestScore)])}
                </h5>
                <p>{getText('basedOnReviews', { count: hotelDetails?.hotelScore?.guestScoreCount })}</p>
              </div>
            </div>
          </Col>
        )}
        <Col xs={12} md={6} lg={6}>
          <ReviewCart reviews={hotelDetails?.reviewData} />
        </Col>
      </Row>
      {isNil(hotelDetails?.geoLocation) ? (
        <Skeleton width="100%" height={450} className="loading-map" />
      ) : (
        <iframe
          src={`https://maps.google.com/maps?q=${hotelDetails?.geoLocation?.latitude},${hotelDetails?.geoLocation?.longitude}&hl=es;&output=embed`}
          width="100%"
          height="450"
          style={maps}
          aria-hidden="false"
        />
      )}
      {hotelDetails?.hotelPolices && <h3 className="titles">{getText('hotelPolicies')}</h3>}
      <Row gap={16}>
        {hotelDetails?.hotelPolices && (
          <Col xs={12} md={6} lg={6}>
            <PolicyCart policies={hotelDetails?.hotelPolices} className="policies" />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Overview;
