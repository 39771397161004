import { theme } from 'styles';

export const bookingsTabsContentContainer = {
  '.not-have-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 48,

    '.not-have-title': {
      color: theme.gray800,
      fontWeight: 500,
    },

    '& > svg': {
      maxHeight: 300,
    },
  },
};
