import { theme } from 'styles';

export const hotelContainer = {
  '.subscription-banner': {
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
  },

  '.background-photo': {
    position: 'relative',
    height: 350,
    background: `linear-gradient(360deg, ${theme.infoLighter} 0%,${theme.secondaryLightDarker} 100%)`,
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 128,

    '@media (max-width: 767px)': {
      alignItems: 'flex-start',
    },

    '.text': {
      textAlign: 'center',
      marginTop: 64,

      '.subtitle': {
        color: theme.gray400,
      },
      '@media (max-width: 992px)': {
        marginBottom: 64,
        marginTop: 0,
      },
    },

    '.travel-logo': {
      maxWidth: 300,
      maxHeight: 170,
      height: '100%',
      position: 'absolute',
      bottom: '25%',
      right: 0,
    },
  },

  '.title': {
    textAlign: 'center',
  },

  '.benefits-description': {
    textAlign: 'center',
    color: theme.gray400,
    marginBottom: 48,
  },

  '.benefits-container': {
    width: '100%',
    padding: '0 128px',
    marginBottom: 64,

    '.image': {
      width: 70,
      height: 65,
      marginBottom: 16,

      '@media (max-width: 576px)': {
        width: 140,
        height: 130,
      },
    },

    '.benefits-title': {
      marginBottom: 8,
    },

    '.benefits-subtitle': {
      marginBottom: 16,
      color: theme.gray400,
      fontSize: 13,
      overflow: 'hidden',
      display: ' -webkit-box',
      WebkitLineClamp: 3,
      lineClamp: 3,
      WebkitBoxOrient: 'vertical',
      maxWidth: 250,
      minHeight: 46,
    },

    '@media (max-width: 576px)': {
      padding: '0 64px 0 80px',
    },
  },

  '.button-container': {
    padding: 0,

    '.icon-container': {
      padding: 8,
      borderRadius: '50%',
      marginRight: 4,
      backgroundColor: theme.primary,
    },

    '.button-text': {
      fontWeight: 600,
    },

    '.help-button': {
      color: theme.white,
    },
  },

  '.subscription-container': {
    backgroundColor: theme.blueDarker,
    color: theme.white,
    padding: '64px 128px',
    justifyContent: 'stretch',

    '.subscription-subtitle': {
      marginBottom: 32,
    },

    '.column-cart': {
      display: 'flex',
    },
  },

  '.contact-container': {
    backgroundColor: theme.blueDarker2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,

    '& > *': {
      color: theme.white,
    },

    '.help-text': {
      marginRight: 8,
    },

    '.button-text': {
      fontWeight: 400,
    },
  },

  '.mobile-container': {
    padding: '64px 128px',

    '.frame-container': {
      backgroundColor: theme.infoLight,
      borderRadius: 18,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      position: 'relative',

      '.right-frame-image': {
        maxWidth: 130,
        maxHeight: 85,
        position: 'absolute',
        right: 0,
        bottom: 0,
      },

      '.left-frame-image': {
        maxWidth: 130,
        maxHeight: 120,
        position: 'absolute',
        left: 0,
        top: 0,
      },

      '.mobile-info': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0 72px',

        '.info-container': {
          marginTop: 32,

          '.description': {
            color: theme.gray400,
            margin: '0 32px 16px 0',
          },

          '.button': {
            padding: 0,

            '@media (max-width: 992px)': {
              margin: 0,
            },
          },
        },

        '.mobile-view-image': {
          maxHeight: 350,

          '@media (max-width: 992px)': {
            height: 450,
            maxHeight: 450,
          },
        },

        '@media (max-width: 992px)': {
          margin: '20px 32px 0 48px',
        },
      },
    },

    '@media (max-width: 767px)': {
      padding: '24px 64px',
    },
  },
};

export const benefitsModalContainer = {
  '.scrollable-container': {
    height: 300,
    overflow: 'auto',
  },

  '.scrollable-container::-webkit-scrollbar': {
    display: 'none',
  },

  '.horizontal-line': {
    backgroundColor: theme.gray200,
  },
};
