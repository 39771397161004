import { theme } from 'styles';

export const cardContainer = {
  padding: 16,

  '.hotel-info': {
    backgroundColor: theme.gray100,
    borderRadius: '10px 0px 0px 10px',
    padding: 16,

    '.margin': {
      marginBottom: 8,
    },

    '.info': {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,

      '.entertainment-badge': {
        borderRadius: 25,
        margin: '0 8px 16px 0',
      },

      '.location': {
        marginBottom: 8,
      },

      '.amenities': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '.amenities-info': {
          marginRight: 4,
        },
      },
    },
  },

  '.price-info': {
    backgroundColor: theme.gray50,
    borderRadius: '0px 10px 10px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: 16,

    '& > *': {
      marginBottom: 8,
    },

    '.price': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '.for-night': {
      color: theme.gray200,
    },
  },
};
