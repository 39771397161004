import { useEffect } from 'react';
import {
  isNil,
  // REMOVE TAG HIDE FOR FIRST VERSION
  //  pick
} from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { changeUserCurrency, useCurrencies } from '@oneecosystem/dealshaker-core';
import {
  Col,
  Row,
  CurrencyLanguageCart,
  // REMOVE TAG HIDE FOR FIRST VERSION
  // LeftSubscriptionBanner,
  // SavedTravelersCart,
  // REMOVE TAG HIDE FOR FIRST VERSION
  // SubscriptionDetailsCart,
  UserCart,
  WalletTransactionCart,
} from 'components';
import { DEFAULT_CURRENCY } from 'enums';
// REMOVE TAG HIDE FOR FIRST VERSION
// import { useUserStore } from 'stores';
import { ReactComponent as LeftBottomFrame } from 'assets/images/left-bottom-frame.svg';
import { ReactComponent as RightBottomFrame } from 'assets/images/right-bottom-frame.svg';
import { myProfileContainer } from './styles';

const MyProfile = () => {
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const { isAuthenticated } = useUserStore((userState) => pick(userState, ['isAuthenticated']));
  const { getText, language } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);

  useEffect(() => {
    document
      .getElementById('#my-profile-header')
      .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }, []);

  return (
    <div css={myProfileContainer} id="#my-profile-header">
      {/*
       // REMOVE TAG HIDE FOR FIRST VERSION
      {isAuthenticated && <LeftSubscriptionBanner show className="subscription-banner" />} */}

      <div className="frame-container">
        <RightBottomFrame className="right-bottom-frame-image" />
        <LeftBottomFrame className="left-bottom-frame-image" />
        <div className="wallets-container">
          <h2>{getText('myProfile')}</h2>
        </div>
      </div>
      <Row gap={16} className="container">
        <Col lg={5} md={12} sm={12} xs={12} className="user-content">
          <UserCart />
        </Col>
        <Col lg={7} md={12} sm={12} xs={12} className="user-languages-container">
          <h4 className="title">{getText('transactions')}</h4>
          <WalletTransactionCart />

          <h4 className="title">{getText('languageAndCurrency')}</h4>
          <CurrencyLanguageCart
            iconName="translate"
            className="language-cart"
            title={getText('language')}
            language={language?.name}
          />
          <CurrencyLanguageCart
            iconName="payments"
            className="currency-cart"
            title={getText('currency')}
            currency={!isNil(selectedCurrency) ? selectedCurrency : DEFAULT_CURRENCY}
            onSaveCurrency={(val) => changeUserCurrency(val)}
          />

          {/*
           REMOVE TAG HIDE FOR FIRST VERSION
          <h4 className="title">{getText('savedTravelers')}</h4>
          <SavedTravelersCart /> */}

          {/*
           REMOVE TAG HIDE FOR FIRST VERSION
           <h4 className="title">{getText('subscriptionDetails')}</h4>
          <SubscriptionDetailsCart /> */}
        </Col>
      </Row>
    </div>
  );
};

export default MyProfile;
