import { theme } from 'styles';

export const localizationContainer = {
  backgroundColor: theme.white,
  border: `1px solid ${theme.gray150}`,
  padding: '8px 16px',
  borderRadius: 20,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '.vertical-line': {
    borderLeft: `2px solid ${theme.gray700}`,
    height: 12,
    margin: '0 8px',
  },
};

export const modalContainer = {
  '.modal-content': {
    maxHeight: 'calc(100vh - 400px)',
    overflowY: 'auto',
    minHeight: '100%',
    padding: 8,

    '.title': {
      marginBottom: 16,
    },
  },
};

export const itemContainer = (isActive) => ({
  padding: '16px 8px',
  marginBottom: 16,
  border: isActive ? `1px solid ${theme.gray150}` : 'none',
  borderRadius: isActive ? 8 : 'none',

  '.name': {
    color: theme.gray300,
  },

  '&: hover': {
    border: `1px solid ${theme.gray150}`,
    borderRadius: 8,
  },
});
