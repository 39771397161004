import { theme } from 'styles';

export const container = {
  position: 'relative',

  '.to-top-button': {
    position: 'absolute',
    bottom: 32,
    right: 20,
    textAlign: 'center',

    '.button': {
      borderRadius: 50,
      width: 57,
      height: 57,
      marginBottom: 4,

      '@media (max-width: 992px)': {
        width: 37,
        height: 37,
      },
    },

    '.text': {
      fontWeight: 500,
      color: theme.gray700,
    },

    '@media (max-width: 992px)': {
      right: 0,
      bottom: 72,
    },
  },
};

export const filtersContainer = {
  padding: '0 112px',

  '.horizontal-line': {
    backgroundColor: theme.gray200,
    margin: 16,
    marginTop: 0,
  },

  '@media (max-width: 992px)': {
    padding: '0 64px',
  },
};

export const scrollableContainer = (hasScroll) => [
  {
    padding: '0 112px',
    display: 'flex',
    flexDirection: 'column',
    // REMOVE TAG HIDE FOR FIRST VERSION
    // height: hasScroll && 'calc(100vh - 280px)',
    height: hasScroll && 'calc(100vh - 200px)',
    overflow: 'auto',

    '.pagination': {
      paddingBottom: 16,
    },

    '.amenity-badge': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0 16px',

      '& > *': {
        marginRight: 8,
      },
    },

    '.amenities-styles': {
      padding: 10,
    },

    '.not-have-container': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      '.not-have-title': {
        color: theme.gray800,
        fontWeight: 500,
      },

      '.not-have-subtitle': {
        color: theme.gray700,
      },

      '& > svg': {
        maxHeight: 300,
      },
    },

    '@media (max-width: 992px)': {
      padding: '0 64px',
    },
  },
];

export const banner = {
  backgroundColor: theme.blueDark,
  width: '100%',
  height: 46,
  zIndex: 1,
};

export const badgeContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};
