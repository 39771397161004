import { useEffect, useRef, useState } from 'react';
// REMOVE TAG HIDE FOR FIRST VERSION
// import { pick } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
// REMOVE TAG HIDE FOR FIRST VERSION
// import {  useCurrencies } from '@oneecosystem/dealshaker-core';
import {
  Button,
  Col,
  HomeFilterBar,
  Icon,
  Image,
  Modal,
  Row,
  // REMOVE TAG HIDE FOR FIRST VERSION
  // PriceLabel,
  // showApiError,
  // LeftSubscriptionBanner,
  // SubscriptionCart,
} from 'components';
import {
  ROUTES,
  // REMOVE TAG HIDE FOR FIRST VERSION
  // SUBSCRIPTION_TYPES
  // DEFAULT_CURRENCY,
} from 'enums';
// REMOVE TAG HIDE FOR FIRST VERSION
// import { useUserStore, initSubscriptionTypes, useSubscriptionTypes } from 'stores';
// import { getAllSubscriptions } from 'services';
import { ReactComponent as TravelLogo } from 'assets/images/travel-logo.svg';
import { ReactComponent as MobileView } from 'assets/images/mobile-view.svg';
import { ReactComponent as RightFrame } from 'assets/images/right-frame.svg';
import { ReactComponent as LeftFrame } from 'assets/images/left-frame.svg';
import appStore from 'assets/images/app-store.png';
import googlePlay from 'assets/images/google-play.png';
import { memberBenefits } from './config';
import { hotelContainer, benefitsModalContainer } from './styles';

const HotelsHome = () => {
  const { getText } = useTranslations();
  // // REMOVE TAG HIDE FOR FIRST VERSION
  // const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  // const subscriptionTypes = useSubscriptionTypes();
  // const { isAuthenticated } = useUserStore((userState) => pick(userState, ['isAuthenticated']));
  const [selectedBenefits, setSelectedBenefits] = useState({});
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const [subscriptions, setSubscriptions] = useState([]);
  const modalRef = useRef();

  // REMOVE TAG HIDE FOR FIRST VERSION
  // const convertedToLocalCurrencyPrice = (price) =>
  //   price &&
  //   <PriceLabel
  //   fiat={Number(price)}
  //   fiatOriginCurrency={DEFAULT_CURRENCY.code}
  //   fiatDesiredCurrency={selectedCurrency?.code}
  // />

  // const subscriptionWithIncludes = subscriptions
  //   ?.map((el) => ({
  //     ...el,
  //     includes: subscriptionTypes.find((item) => item.name === el.subscriptionTypeName)?.includes,
  //   }))
  //   .filter((item) => item.isActive && item.isVisible);

  // const filteredSubscriptions = (type) =>
  //   subscriptionWithIncludes
  //     ?.filter((el) => el?.subscriptionTypeName?.toLowerCase() === type?.toLowerCase())
  //     ?.map((el) => ({
  //       ...el,
  //       label: <p>
  // {convertedToLocalCurrencyPrice(el?.price)} <span>{` - ${el?.duration} ${getText('monthsDuration')}`}</span>
  // </p>,
  //       value: el.id,
  //     }));

  useEffect(() => {
    // REMOVE TAG HIDE FOR FIRST VERSION
    // initSubscriptionTypes();
    // fetchAllSubscriptions();
    handleScrollToTop();
  }, []);
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const fetchAllSubscriptions = async () => {
  //   const [res, err] = await getAllSubscriptions({ pageIndex: 1, pageSize: 500, isVisible: true, isAvailable: true });
  //   err ? showApiError(err) : setSubscriptions(res?.results);
  // };

  const handleOpenBenefitsModal = (value) => {
    modalRef.current?.open();
    setSelectedBenefits(value);
  };

  const handleScrollToTop = () =>
    document.getElementById('#home-header').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

  return (
    <div css={hotelContainer} id="#home-header">
      {/*
      REMOVE TAG HIDE FOR FIRST VERSION
       {isAuthenticated && <LeftSubscriptionBanner show className="subscription-banner" />} */}
      <div className="background-photo">
        <div className="text">
          <h1>{getText('saveBigNextTrip')}</h1>
          <p className="subtitle">{getText('greatDeals')}</p>
        </div>
        <TravelLogo className="travel-logo" />
        <HomeFilterBar />
      </div>
      <h2 className="title">{getText('memberBenefits')}</h2>
      <p className="benefits-description">{getText('memberRecieiveBenefits')}</p>
      <Row gap={16} className="benefits-container">
        {memberBenefits(getText).map((el, ind) => (
          <Col lg={4} md={6} sm={12} key={ind}>
            <Image src={el.image} className="image" />
            <h5 className="benefits-title">{el.title}</h5>
            <p className="benefits-subtitle">{el.subtitle}</p>
            <Button small type="link" className="button-container" onClick={() => handleOpenBenefitsModal(el)}>
              <div className="icon-container">
                <Icon material iconName="arrow_forward" size={16} />
              </div>
              <p className="button-text">{getText('learnMore')}</p>
            </Button>
          </Col>
        ))}
      </Row>
      {/*
      REMOVE TAG HIDE FOR FIRST VERSION
      <div className="subscription-container">
        <h2 className="subscription-title">{getText('chooseTheRightPlan')}</h2>
        <p className="subscription-subtitle">{getText('chooseSubscriptionDescription')}</p>
        <Row gap={16} dFlex>
          <Col lg={4} md={6} sm={12} className="column-cart">
            <SubscriptionCart
              type={SUBSCRIPTION_TYPES.BASIC}
              options={filteredSubscriptions(SUBSCRIPTION_TYPES.BASIC)}
            />
          </Col>
          <Col lg={4} md={6} sm={12} className="column-cart">
            <SubscriptionCart
              type={SUBSCRIPTION_TYPES.PREMIUM}
              options={filteredSubscriptions(SUBSCRIPTION_TYPES.PREMIUM)}
            />
          </Col>
          <Col lg={4} md={6} sm={12} className="column-cart">
            <SubscriptionCart
              type={SUBSCRIPTION_TYPES.GOLDEN_UNLIMITED}
              options={filteredSubscriptions(SUBSCRIPTION_TYPES.GOLDEN_UNLIMITED)}
            />
          </Col>
        </Row>
      </div> */}
      <div className="contact-container">
        <h5 className="help-text">{getText('helpForChoosingPlan')}</h5>
        <Button small type="link" className="button-container">
          <div className="icon-container">
            <Icon material iconName="arrow_forward" size={16} color="black" />
          </div>
          <a href="mailto: sales@onevoyage.com" className="help-button">
            <p className="button-text">{getText('contactSales')}</p>
          </a>
        </Button>
      </div>
      <div className="mobile-container">
        <div className="frame-container">
          <RightFrame className="right-frame-image" />
          <LeftFrame className="left-frame-image" />
          <div className="mobile-info">
            <div className="info-container">
              <h3>{getText('getOneVoyageApp')}</h3>
              <p className="description">{getText('getOneVoyageAppDescription')}</p>
              <div>
                <Button small type="link" linkTo={ROUTES.AppStore} className="button">
                  <Image src={appStore} linkTo={ROUTES.AppStore} className="button" />
                </Button>
                <Button small type="link" linkTo={ROUTES.GoogleStore} className="button">
                  <Image src={googlePlay} linkTo={ROUTES.GoogleStore} className="button" />
                </Button>
              </div>
            </div>
            <MobileView className="mobile-view-image" />
          </div>
        </div>
      </div>
      <Modal ref={modalRef} closeIcon css={benefitsModalContainer}>
        <h4>{selectedBenefits.title}</h4>
        <hr className="horizontal-line" />
        <div className="scrollable-container">{selectedBenefits.description}</div>
      </Modal>
    </div>
  );
};

export default HotelsHome;
